import * as React from 'react'
import {ZakaznikForm} from './ZakaznikForm'
import { Text, Label, Separator, Stack, Checkbox, ITheme, getTheme, TextField, DefaultButton, PrimaryButton } from '@fluentui/react';
import { IZakaznikDto } from '../../../Types/Zadost/IZakaznik';
import { KeyValuePair, rebex, validate, checkAllRules, validateLasValues } from '../../Validation';
import { IZakaznikFormaEnum } from '../../../Types/Zadost/IZakaznikFormaEnum';


interface IKontaktniInformaceProps {   
    zadatel: IZakaznikDto
    investor: IZakaznikDto
    isValid: (value: boolean) => void,
    onRef: (ref: any) => void,
    updateKontakt: (zadatel: IZakaznikDto, investor: IZakaznikDto, zadavatelInvestor: boolean) => void
}

interface IKontaktniInformaceState {
    isValid: boolean;
    zadatel: IZakaznikDto
    zadatelValidation: KeyValuePair
    investorSame: boolean
    investor: IZakaznikDto
    investorValidation: KeyValuePair
}


export class KontaktniInformace extends React.Component<IKontaktniInformaceProps, IKontaktniInformaceState>
{
    private theme: ITheme;

    constructor(props: IKontaktniInformaceProps) {
        super(props);
        this.theme = getTheme();
        this.state = {
            isValid: false,
            investorSame: true,
            zadatel: { ...props.zadatel },
            investor: { ...props.investor },
            zadatelValidation: {
                "email": {
                    regexp: rebex.EmailRegExp,
                    required: true,
                    validationError: "Zadejte prosím platnou e-mail adresu",
                    isValid: true,
                    validated: false
                },
                "kontakt": {
                    required: true,
                    validationError: "Prosím zadajte kontaktní osobu",
                    isValid: true,
                    validated: false
                },
                "telefon": {
                    required: true,
                    regexp: rebex.NumbersOnlyRegExp,
                    validationError: "Telefonní číslo musí obsahovat pouze číslice",
                    isValid: true,
                    validated: false
                },
                "ulice": {
                    required: true,
                    validationError: "Musíte zadat adresu",
                    isValid: true,
                    validated: false
                },
                "mesto": {
                    required: true,
                    validationError: "Musíte zadat adresu",
                    isValid: true,
                    validated: false
                },
                "psc": {
                    required: true,
                    regexp: rebex.NumbersOnlyRegExp,
                    validationError: "Musíte zadat platné psc",
                    isValid: true,
                    validated: false
                }
            },
            investorValidation: {
                "email": {
                    regexp: rebex.EmailRegExp,
                    required: true,
                    validationError: "Zadejte prosím platnou e-mail adresu",
                    isValid: true,
                    validated: false
                },
                "kontakt": {
                    required: true,
                    validationError: "Prosím zadajte kontaktní osobu",
                    isValid: true,
                    validated: false
                },
                "telefon": {
                    required: true,
                    regexp: rebex.NumbersOnlyRegExp,
                    validationError: "Telefonní číslo musí obsahovat pouze číslice",
                    isValid: true,
                    validated: false
                },
                "ulice": {
                    required: true,
                    validationError: "Musíte zadat adresu",
                    isValid: true,
                    validated: false
                },
                "mesto": {
                    required: true,
                    validationError: "Musíte zadat adresu",
                    isValid: true,
                    validated: false
                },
                "psc": {
                    required: true,
                    regexp: rebex.NumbersOnlyRegExp,
                    validationError: "Musíte zadat platné psc",
                    isValid: true,
                    validated: false
                }
            }
        }
    }

    componentDidMount() {
        this.theme = getTheme();
        this.setDefaultInvestorValues(this.props.investor);
        this.setDefaultZadavatelValues(this.props.zadatel);
    }

    setDefaultZadavatelValues(zadatel: IZakaznikDto) {
        this.setState({
            zadatel: { ...zadatel },
            zadatelValidation: {
                "email": {
                    regexp: rebex.EmailRegExp,
                    lastValue: zadatel.email,
                    required: true,
                    validationError: "Zadejte prosím platnou e-mail adresu",
                    isValid: true,
                    validated: false
                },
                "kontakt": {
                    required: true,
                    lastValue: zadatel.kontakt,
                    validationError: "Prosím zadajte kontaktní osobu",
                    isValid: true,
                    validated: false
                },
                "telefon": {
                    required: true,
                    lastValue: zadatel.telefon,
                    regexp: rebex.NumbersOnlyRegExp,
                    validationError: "Telefonní číslo musí obsahovat pouze číslice",
                    isValid: true,
                    validated: false
                },
                "ulice": {
                    required: true,
                    lastValue: zadatel.ulice,
                    validationError: "Musíte zadat adresu",
                    isValid: true,
                    validated: false
                },
                "mesto": {
                    required: true,
                    lastValue: zadatel.mesto,
                    validationError: "Musíte zadat adresu",
                    isValid: true,
                    validated: false
                },
                "psc": {
                    required: true,
                    lastValue: zadatel.psc,
                    regexp: rebex.NumbersOnlyRegExp,
                    validationError: "Musíte zadat platné psc",
                    isValid: true,
                    validated: false
                }
            }
        }, () => {
            if (zadatel.email != "") {
                const zadavatelVal = validateLasValues(this.state.zadatelValidation);
                this.setState({
                    zadatelValidation: zadavatelVal,
                })
            }
        }
        )          
    }

    setDefaultInvestorValues( investor: IZakaznikDto) {
        this.setState({
            
            investor: { ...investor },           
            investorValidation: {
                "email": {
                    regexp: rebex.EmailRegExp,
                    lastValue: investor.email,
                    required: true,
                    validationError: "Zadejte prosím platnou e-mail adresu",
                    isValid: true,
                    validated: false
                },
                "kontakt": {
                    required: true,
                    lastValue: investor.kontakt,
                    validationError: "Prosím zadajte kontaktní osobu",
                    isValid: true,
                    validated: false
                },
                "telefon": {
                    required: true,
                    lastValue: investor.telefon,
                    regexp: rebex.NumbersOnlyRegExp,
                    validationError: "Telefonní číslo musí obsahovat pouze číslice",
                    isValid: true,
                    validated: false
                },
                "ulice": {
                    required: true,
                    lastValue: investor.ulice,
                    validationError: "Musíte zadat adresu",
                    isValid: true,
                    validated: false
                },
                "mesto": {
                    required: true,
                    lastValue: investor.mesto,
                    validationError: "Musíte zadat adresu",
                    isValid: true,
                    validated: false
                },
                "psc": {
                    required: true,
                    lastValue: investor.psc,
                    regexp: rebex.NumbersOnlyRegExp,
                    validationError: "Musíte zadat platné psc",
                    isValid: true,
                    validated: false
                }
            },
        }, () => {
            if (investor.email != "") {
                 
                const investorVal = validateLasValues(this.state.investorValidation);
                this.setState({
                    investorValidation: investorVal,
                })
            }
        }
        )
    }



    componentDidUpdate(prevprops: IKontaktniInformaceProps, prevstate: IKontaktniInformaceState) {
        if (this.props.investor != prevprops.investor) {
            this.setDefaultInvestorValues(this.props.investor);            
        }

        if (this.props.zadatel != prevprops.zadatel) {
            this.setState({ zadatel: { ...this.props.zadatel } })
            this.setDefaultZadavatelValues(this.props.zadatel);   
        }

        if (this.state.zadatel.forma !== prevstate.zadatel.forma) {
            console.log("Zadani Changed");
            const zValidation = { ...this.state.zadatelValidation }
            if (this.state.zadatel.forma === IZakaznikFormaEnum.prvnickaOsoba) {         
                zValidation["firma"] = {
                    required: true,
                    isValid: true,
                    validated: false

                }
                zValidation["ic"] = {
                    required: true,
                    isValid: true,
                    validated: false
                }
                zValidation["dic"] = {
                    required: true,
                    isValid: true,
                    validated: false
                }
            }
            else {
                delete zValidation['firma'];
                delete zValidation['ic'];
                delete zValidation['dic'];
            }
            this.setState({ zadatelValidation: zValidation });
        }
        if (this.state.investor.forma !== prevstate.investor.forma) {
            const iValidation = { ...this.state.investorValidation }
            if (this.state.investor.forma === IZakaznikFormaEnum.prvnickaOsoba) {

                iValidation["firma"] = {
                    required: true,                    
                    isValid: true,
                    validated: false
                }
                iValidation["ic"] = {
                    required: true,                    
                    isValid: true,
                    validated: false
                }
                iValidation["dic"] = {
                    required: true,
                    isValid: true,
                    validated: false
                }
            }
            else {
                delete iValidation['firma'];
                delete iValidation['ic'];
                delete iValidation['dic'];
            }           
            this.setState({ investorValidation: iValidation });
        }

        let zadavelValidation = checkAllRules(this.state.zadatelValidation);
        let investorValidation = this.state.investorSame;

        if (investorValidation && this.state.zadatelValidation != prevstate.zadatelValidation) {            
            this.setState({ isValid: zadavelValidation && investorValidation });
        }
        if (!this.state.investorSame && this.state.investorValidation != prevstate.investorValidation) {
            investorValidation = checkAllRules(this.state.investorValidation);
            this.setState({ isValid: zadavelValidation && investorValidation });
        }
    }

    handleZadatelChangeBind = this.handleZadatelChangeChange.bind(this);
    handleZadatelChangeChange(event: any) {  
        const { name, value } = event.target;
        this.setState({
            zadatel: { ...this.state.zadatel, [name]: value },
            zadatelValidation: { ...this.state.zadatelValidation, [name]: validate(value, this.state.zadatelValidation[name]) },
            investor: (this.state.investorSame ? { ...this.state.zadatel, [name]: value } : this.state.investor),
            investorValidation: (this.state.investorSame ? {
                ...this.state.investorValidation, [name]: validate(value, this.state.investorValidation[name]),
            } : this.state.investorValidation)        
        });
    }

    handleZadavatelChangedNameValueBind = this.handleZadavatelChangedNameValue.bind(this);
    handleZadavatelChangedNameValue(name: string, value: any)
    {
        this.setState({
            zadatel: { ...this.state.zadatel, [name]: value },
            zadatelValidation: {
                ...this.state.zadatelValidation, [name]: validate(value, this.state.zadatelValidation[name]),
            },
            investor: (this.state.investorSame ? { ...this.state.zadatel, [name]: value } : this.state.investor),
            investorValidation: (this.state.investorSame ? {
                ...this.state.investorValidation, [name]: validate(value, this.state.investorValidation[name]),
            } : this.state.investorValidation),
        }, () => console.log(this.state));
    }

    handleInvestorChangeBind = this.handleInvestorChange.bind(this);
    handleInvestorChange(event: any) {
        const { name, value } = event.target;
        this.setState({
            investor: { ...this.state.investor, [name]: value },
            investorValidation: { ...this.state.investorValidation, [name]: validate(value, this.state.investorValidation[name]) }
        });
    }

    handleInvestorChangedNameValueBind = this.handleInvestorChangedNameValue.bind(this);
    handleInvestorChangedNameValue(name: string, value: any) {
        this.setState({
            investor: { ...this.state.investor, [name]: value },
            investorValidation: { ...this.state.investorValidation, [name]: validate(value, this.state.investorValidation[name]) }
        });
    }

    saveData() {
        this.props.updateKontakt(this.state.zadatel, this.state.investor, this.state.investorSame);        
    }

    render() {
        return (
            <Stack styles={{ root: { paddingTop: 25 } }}>
                <Text>
                    Po vyplnění kontaktních údajů je možno provést registraci žadatele zadáním přihlašovacího jména a hesla pro pozdější nebo opakované přihlášení. U registrovaného uživatele budou kontaktní údaje po přihlášení vyplněny automaticky.
                </Text>
                <Label style={{ fontSize: this.theme.fonts.large.fontSize }} styles={{ root: { color: this.theme.palette.themePrimary } }}>Žadatel</Label>
                <Separator styles={{ root: { paddingTop: 0, fontSize: "8px" } }} />
                <ZakaznikForm validation={this.state.zadatelValidation}
                    handleChanged={this.handleZadatelChangeBind}
                    handleChangedNameValue={this.handleZadavatelChangedNameValueBind}
                    disabled={false} id="ZadatelForm" key="ZadatelForm" zakaznik={this.state.zadatel} />
                <Separator />
                <Checkbox
                    checked={this.state.investorSame}
                    onChange={(e: any, newValue?: boolean) => {
                        this.setState({
                            investorSame: (newValue ? newValue : false),
                            investor:this.state.zadatel
                        })
                    }}
                    styles={{ root: { paddingTop: "25px", fontSize: this.theme.fonts.large.fontSize }, text: { fontWeight: "600", fontSize: this.theme.fonts.large.fontSize, color: this.theme.palette.themePrimary } }} label="Stavebník / Investor" />
                <ZakaznikForm  validation={this.state.investorValidation}
                    handleChanged={this.handleInvestorChangeBind}
                    handleChangedNameValue={this.handleInvestorChangedNameValueBind}
                    disabled={this.state.investorSame} id="InvestorForm" key="InvestorForm" zakaznik={this.state.investor} />

                <Stack horizontal horizontalAlign="center" styles={{ root: { paddingTop: 25, paddingBottom: 25 } }}>
                    <PrimaryButton text="Pokračovat" disabled={!this.state.isValid} onClick={this.saveData.bind(this)} />
                </Stack>
            </Stack>);
    }
}


//export const StoreToKontaktniInformaceState = (state: IAppStore) => ({

//});

//export const StoreToKontaktniInformaceDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
//    return {
//        setZadostKontakt: (zadatel: IZakaznikDto, investor: IZakaznikDto, zadavatelInvestor: boolean) => dispatch(SetZadostKontakt(zadatel, investor, zadavatelInvestor)),
//    };
//};

export default KontaktniInformace;


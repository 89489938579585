import * as React from 'react'
import { IZakaznikDto } from '../../../Types/Zadost/IZakaznik'
import { Stack, TextField, Toggle, Check } from '@fluentui/react'
import { KeyValuePair } from '../../Validation';
import { IZakaznikFormaEnum } from '../../../Types/Zadost/IZakaznikFormaEnum';
interface IZakaznikPanelProps
{
    id:string
    zakaznik: IZakaznikDto;
    validation: KeyValuePair;
    disabled: boolean;
    handleChanged?: (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => void
    handleChangedNameValue?: (key: string, value: any) => void
}


export const ZakaznikForm: React.FC<IZakaznikPanelProps> = (props) => {

    const { disabled, handleChanged, zakaznik, validation } = props;
    const textStyle = { subComponentStyles: { label: { root: { width: "185px" } } } };
    return (
        <Stack id={props.id}>
            <Toggle disabled={disabled}                 
                checked={zakaznik.forma === IZakaznikFormaEnum.prvnickaOsoba}
                label={"Právní forma"}
                onText="Právnická osoba"
                offText="Fyzická osoba"
                onChange={(event: any, checked: boolean | undefined) => {
                    props.handleChangedNameValue && props.handleChangedNameValue("forma", (checked ? IZakaznikFormaEnum.prvnickaOsoba : IZakaznikFormaEnum.fyzickaOsoba))
                }}
            />
            {(zakaznik.forma === IZakaznikFormaEnum.prvnickaOsoba ?
                <React.Fragment>
                <TextField styles={textStyle}
                    underlined
                    disabled={disabled}
                    required={validation["ic"] ? validation["ic"].required : false}
                    label="Ič"
                    value={zakaznik.ic}
                    name="ic"
                    errorMessage={validation["ic"] && !validation["ic"].isValid ? validation["ic"].validationError : ""}
                        onChange={handleChanged} />
                    <TextField styles={textStyle}
                        underlined
                        disabled={disabled}
                        required={validation["dic"] ? validation["dic"].required : false}
                        label="Dič"
                        value={zakaznik.dic}
                        name="dic"
                        errorMessage={validation["dic"] && !validation["dic"].isValid ? validation["dic"].validationError : ""}
                        onChange={handleChanged} />

                    <TextField styles={textStyle}
                        underlined
                        disabled={disabled}
                        required={validation["firma"] ? validation["firma"].required : false}
                        label="Firma/organizace"
                        value={zakaznik.firma}
                        name="firma"
                        errorMessage={validation["firma"] && !validation["firma"].isValid ? validation["firma"].validationError : ""}
                        onChange={handleChanged}
                    />

                    </React.Fragment>
                :null)}
          

            <TextField styles={textStyle}
                underlined
                required={validation["kontakt"] ? validation["kontakt"].required : false}
                name="kontakt"
                disabled={disabled}
                label="Kontaktní osoba"
                value={zakaznik.kontakt}
                errorMessage={validation["kontakt"] && !validation["kontakt"].isValid ? validation["kontakt"].validationError : ""}
                onChange={handleChanged}
               
            />
            <TextField styles={textStyle}
                underlined
                required={validation["ulice"] ? validation["ulice"].required : false}
                disabled={disabled}
                label="Adresa (ulice č.o. / č.p.)"
                name="ulice"
                value={zakaznik.ulice}
                errorMessage={validation["ulice"] && !validation["ulice"].isValid ? validation["ulice"].validationError : ""}
                onChange={handleChanged}
            />
            <TextField styles={textStyle}
                underlined
                required={validation["mesto"] ? validation["mesto"].required : false}
                disabled={disabled}
                label="Město / obec"
                name="mesto"
                value={zakaznik.mesto}
                errorMessage={validation["mesto"] && !validation["mesto"].isValid ? validation["mesto"].validationError : ""}
                onChange={handleChanged}                
            />
            <TextField styles={textStyle}
                underlined
                required={validation["psc"] ? validation["psc"].required : false}
                disabled={disabled}
                name="psc"
                label="PSČ"
                value={zakaznik.psc}
                errorMessage={validation["psc"] && !validation["psc"].isValid ? validation["psc"].validationError : ""}
                onChange={handleChanged}                
            />
            <TextField styles={textStyle}
                underlined
                required={validation["stat"] ? validation["stat"].required : false}
                disabled={disabled}
                name="stat"
                label="Stát"
                value={zakaznik.stat}
                errorMessage={validation["stat"] && !validation["stat"].isValid ? validation["stat"].validationError : ""}
                onChange={handleChanged}                
            />
            <TextField styles={textStyle}
                underlined
                required={validation["email"] ? validation["email"].required : false}
                disabled={disabled}
                name="email"
                label="E-mail"
                value={zakaznik.email}
                errorMessage={validation["email"] && !validation["email"].isValid ? validation["email"].validationError : ""}
                onChange={handleChanged}
            />
            <TextField styles={textStyle}
                underlined
                required={validation["telefon"] ? validation["telefon"].required : false}
                disabled={disabled}
                name="telefon"
                label="Telefonní číslo"
                value={zakaznik.telefon}
                errorMessage={validation["telefon"] && !validation["telefon"].isValid ? validation["telefon"].validationError : ""}
                onChange={handleChanged}                
            />
        </Stack>
    )
}

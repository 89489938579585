import * as React from 'react'
import { Stack, Label, ChoiceGroup, IChoiceGroupOption, TextField  } from '@fluentui/react';
import { ZakaznikForm } from '../../zadost/KontaktniInformace/ZakaznikForm';
import { KeyValuePair } from '../../Validation';
import { IZakaznikDto } from '../../../Types/Zadost/IZakaznik';
import { IZakaznikFormaEnum } from '../../../Types/Zadost/IZakaznikFormaEnum';
import { IZadostApi } from '../../../Services/IZadostApi';
import { IZadostListItem } from '../../../Types/Admin/ZadostListItem';
import { IApiError } from '../../../Types/IApiError';
import { IZadost } from '../../../Types/Zadost/IZadost';

interface IZadostDetailFormProps {
    zadost: IZadostListItem;
    zadostApi: IZadostApi;
}

const validation: KeyValuePair = {};

type StavZadosti  = {
    [key: number]: string
}

export const stavZadosti: StavZadosti = {
    0: "Před dokončením uživatelem",
    10: "Založená žádost",
    20: "Vygenerováno potvrzení",
    30: "Vyjádření zpracováno",
    100: "Vyjádření dokončeno",
}

export const ZadostDetailForm: React.FC<IZadostDetailFormProps> = (props) => {
    const [selectedKey, setSelectedKey] = React.useState<string | undefined>('zadatel');
    const [zadost, setZadost] = React.useState <IZadost>()

    const getDetail = async (id: number) => {
        const data = await props.zadostApi.getZadost("", props.zadost.id)        
        if (!(data as IApiError).messageId) {

            const zadost = data as IZadost;
            if (zadost) {
                zadost.investor.forma = (zadost.investor.ic && zadost.investor.ic != '') ? IZakaznikFormaEnum.prvnickaOsoba : IZakaznikFormaEnum.fyzickaOsoba;
                zadost.zadatel.forma = (zadost.zadatel.ic && zadost.zadatel.ic != '') ? IZakaznikFormaEnum.prvnickaOsoba : IZakaznikFormaEnum.fyzickaOsoba;
            }

            setZadost(data as IZadost);
        }
        else {

        }
    }

    React.useEffect(() => {
        if (props.zadost) {
            getDetail(props.zadost.id);            
        }
    }, [props.zadost]);

    const ChoiceGroupChanged = (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption) => {
        if (option) {
            setSelectedKey(option.key);
        }
        console.log(option);
    }

    return (<Stack tokens={{ childrenGap: 10 }} styles={{ root: { width: '100%' } }}>
        <TextField value={zadost?zadost.id.toString():""} label="Číslo žádosti" />
        <TextField value={zadost?stavZadosti[zadost.stav]:""}  label="Stav žádosti" />
        <ChoiceGroup selectedKey={selectedKey} onChange={ChoiceGroupChanged} styles={{ flexContainer: { display: "flex" } }} options={[{ key: "zadatel", text: "Žadatel\u00A0\u00A0" }, { key: "investor", text: "Investor" }]} />
        {zadost ? <ZakaznikForm zakaznik={selectedKey === "zadatel" ? zadost.zadatel : zadost.investor} id={"0"} validation={validation} disabled={true} /> : null}
    </Stack>);
}
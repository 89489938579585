import * as React from 'react'
import { Stack, TextField, Toggle, ComboBox, Checkbox, Link, PrimaryButton, Modal, IComboBoxOption, CheckBase, IComboBox } from '@fluentui/react';
import { useId, useBoolean } from '@fluentui/react-hooks';
import { TextEditor } from '../TextEditor';
import { IVyjadreniApi } from '../../../Services/IVyjadreniApi';
import { IZadostListItem } from '../../../Types/Admin/ZadostListItem';
import { IVyjadreni } from '../../../Types/Admin/IVyjadreni';
import { IApiError } from '../../../Types/IApiError';
import { IVyjadreniOblast } from '../../../Types/Admin/IVyjadreniOblast';
import { VyjadreniDetailFileList } from './VyjadreniDetailFileList';
import { isNumber } from 'util';
import { IUser } from '../../../Types/IUser';

interface IVyjadreniDetailFormProps {
    openTextEditor: (id: number) => void;
    vyjadreniApi: IVyjadreniApi;
    zadost: IZadostListItem;
    user: IUser | undefined
}

const VyjadreniStavOptions: IComboBoxOption[] = [
    {
        key: 0, text: "Nezpracovano",
    },
    {
        key: 10, text: "Data pripravena (optické trasy)",
    },
    {
        key: 20, text: "Náhled připraven",
    },
    {
        key: 30, text: "Kontrola",
    },
    {
        key: 40, text: "Kontrola provedena",
    },
    {
        key: 50, text: "Připraveno k odeslání",
    },
    {
        key: 100, text: "Dokončeno",
    },
]

export const VyjadreniDetailForm: React.FC<IVyjadreniDetailFormProps> = (props) => {

    const [vyjadreni, setVyjadreni] = React.useState<IVyjadreni>()
    const [oblasti, setOblasti] = React.useState<IComboBoxOption[]>()

    const getData = async (id: number) => {
        const o = await props.vyjadreniApi.getOblasti();
        if (!(o as IApiError).messageId) {
            const options: IComboBoxOption[] = (o as IVyjadreniOblast[]).map(item => {
                return {
                    key: item.id,
                    text: item.nazev
                }
            })
            setOblasti(options);
        }

        const v = await props.vyjadreniApi.getVyjadreni(id, "");
        if (!(v as IApiError).messageId) {
            setVyjadreni(v as IVyjadreni);
        }
        
    }

    React.useEffect(() => {
        getData(props.zadost.vyjadreniId);
        console.log(props.user);
    }, [props.zadost]);

    const ExistujiciSiteChecked = (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        if (vyjadreni) {
            setVyjadreni({ ...vyjadreni, pritomnostSite: checked ? checked : false });
        }
    }

    const ProjektSiteChecked = (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        if (vyjadreni) {
            setVyjadreni({ ...vyjadreni, pritomnostProjektSite: checked ? checked : false });
        }
    }

    const ZapracovaneSEKChecked = (event: React.MouseEvent<HTMLElement>, checked?: boolean) => {
        if (vyjadreni) {
            setVyjadreni({ ...vyjadreni, zapracovanaSEK: checked ? checked : false });
        }
    }

    const OblastChanged = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string) => {
        if (option && vyjadreni && isNumber(option.key))
        {
            setVyjadreni({ ...vyjadreni, oblast: { ...vyjadreni.oblast, id: option.key, nazev: option.text } });
        }
    }

    const StavChanged=(event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string) => {
        if (option && vyjadreni && isNumber(option.key)) {
            setVyjadreni({ ...vyjadreni, stav: option.key });
        }
    }

    const PodaSiteChanged = (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        if (vyjadreni) {
            setVyjadreni({ ...vyjadreni, sitePoda: checked ? checked : false });
        }
    }

    const PodaVSBChanged = (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        if (vyjadreni) {
            setVyjadreni({ ...vyjadreni, siteVSB: checked ? checked : false });
        }
    }

    const readonly=(): boolean=>
    {
        if (props.user && props.user.role) {
            return props.user.role.indexOf("admin_write") === -1
        }
        else {
            return true;
        }
    }

    return (
        <>
            {vyjadreni ?
                <Stack tokens={{ childrenGap: 10 }} styles={{ root: { width: '100%' } }}>
                    <TextField label="Číslo vyjádření " value={vyjadreni.id.toString()} />                   
                    <Stack horizontal tokens={{ childrenGap: 10 }}>
                        <Checkbox disabled={readonly()} label="Existence sítě" checked={vyjadreni.pritomnostSite} onChange={ExistujiciSiteChecked} />
                        <Checkbox disabled={readonly()} label="Existence plánované sítě" checked={vyjadreni.pritomnostProjektSite} onChange={ProjektSiteChecked } />
                    </Stack>
                    <Toggle disabled={readonly()} label="Zapracována SEK" defaultChecked onText="Ano" offText="Ne" checked={vyjadreni.zapracovanaSEK} onChange={ZapracovaneSEKChecked} />
                    <ComboBox disabled={readonly()} options={oblasti ? oblasti : []} label="Oblast" selectedKey={vyjadreni.oblast ? vyjadreni.oblast.id : 0} onChange={OblastChanged} />
                    <Checkbox disabled={readonly()} label="PODA a.s." checked={vyjadreni.sitePoda} onChange={PodaSiteChanged} />
                    <Checkbox disabled={readonly()} label="Vysoké školy Báňské - TU Ostrava" checked={vyjadreni.siteVSB} onChange={PodaVSBChanged}  />
                    <ComboBox disabled={readonly()} options={VyjadreniStavOptions} selectedKey={vyjadreni.stav} label="Stav vyjádření" onChange={StavChanged} />
                    {!readonly() ? <PrimaryButton disabled={readonly()} onClick={() => { props.vyjadreniApi.saveVyjadreni(vyjadreni) }} text="Ulož změny v nastavení" /> : null}
                    <br />
                    <VyjadreniDetailFileList readonly={readonly()} vyjadreniApi={props.vyjadreniApi} vyjadreniId={vyjadreni?.id} />
                    <br />
                    {!readonly() ? <PrimaryButton disabled={readonly()} onClick={() => { props.openTextEditor(vyjadreni.id) }} text="Uprav text vyjádření" /> : null}
                </Stack>
                : null}
        </>);
}
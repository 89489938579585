import * as React from "react";
import { Stack, TextField, ComboBox, IComboBoxOption, PrimaryButton, IconButton, ActionButton, IComboBox, DefaultButton } from "@fluentui/react"
import { IVyjadreniFile } from "../../../Types/Admin/IVyjadreniFile";
import { IVyjadreniPrilohaTypEnum } from "../../../Types/Admin/IVyjadreniPrilohaTypEnum";
import { isNumber } from "util";

interface IVyjadreniAddFileFormProps {
    file?: IVyjadreniFile,
    onSave: (id:number,nazev: string, popis: string, typ: IVyjadreniPrilohaTypEnum, file?: File) => void;
    onClose: () => void;
}

const options: IComboBoxOption[] = [
    { key: "0", text: "Vyjádření (PDF)" },
    { key: "1", text: "Mapa oblasti zájmu  (obrázek jpg)" },
    { key: "2", text: "Mapa tras (obrázek jpg)" },
    { key: "3", text: "Mapa plánovaných tras (obrázek jpg)" },
    { key: "4", text: "Příloha emailu (jakýkoli soubor)" }
];

export const VyjadreniAddFileForm: React.FC<IVyjadreniAddFileFormProps> = (props) => {
    const [id, setId] = React.useState(0);
    const [nazev, setNazev] = React.useState("")
    const [popis, setPopis] = React.useState("")
    const [typ, setTyp] = React.useState(0)
    const [file, setFile] = React.useState<File>();
    const [fileName, setFileName] = React.useState("");

    React.useEffect(() => {
        if (props.file) {
            setId(props.file.id);
            setNazev(props.file.nazev);
            setPopis(props.file.popis);
            setTyp(props.file.typ);
        }
    }, [props.file]);


    const onChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event && event.target && event.target.files) {
            if (event.target.files.length === 1) {
                setFile(event.target.files[0]);
                setFileName(event.target.files[0].name);
            }
        }
    }

    const fileButtonClick = () => {
        if (document) {
            document.getElementById('hiddenFileInput')?.click();
        }
    }

    const fileTypeChanged = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string) => {

        if (option && option.key)
            setTyp(parseInt(option.key as string));
    }

    return (<Stack tokens={{ childrenGap: 5 }}>
        <ComboBox selectedKey={typ.toString()} label="Typ souboru" options={options} onChange={fileTypeChanged} />
        <TextField label="Název" value={nazev} onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => setNazev(newValue ? newValue:"")} />
        <TextField label="Popis" multiline value={popis} onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => setPopis(newValue ? newValue : "")}/>
 
        <input id="hiddenFileInput" type="file" onChange={onChange} style={{ display: "none" }} />
        {(id == 0 ?
            <Stack horizontal verticalAlign="end">
                <TextField label="Soubor" value={fileName} />
                <ActionButton iconProps={{ iconName: "FileRequest" }} onClick={fileButtonClick}></ActionButton>
            </Stack> : null)}

        <Stack horizontal horizontalAlign="center" tokens={{
            padding: 10,
            childrenGap: 5
        }}>
            <PrimaryButton onClick={() => props.onSave(id,nazev,popis,typ,file)}>Uložit</PrimaryButton>
            <DefaultButton onClick={props.onClose}>Zavřít</DefaultButton>
        </Stack>
    </Stack>)
}
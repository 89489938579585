import * as React from 'react'
import { IZadost } from '../../../Types/Zadost/IZadost';
import { IZadostListItem } from '../../../Types/Admin/ZadostListItem';
import { Zadost } from '../../../Types/Zadost/Zadost';

var Microsoft: any = window.Microsoft;

interface IZadostiMapFormProps {
    onSelectZadost: (zadost?: IZadostListItem) => void;
    zadosti?: IZadostListItem[],
    mapZoom: number,
    wmsUrl: string,
}
interface IZadostiMapFormState {
}

export class ZadostiMapForm extends React.Component<IZadostiMapFormProps, IZadostiMapFormState >
{
    private map: Microsoft.Maps.Map | undefined;
    private layer: Microsoft.Maps.Layer | undefined;   

    constructor(props: IZadostiMapFormProps) {
        super(props);
        
    }

    mapclick(e: any) {
        var point = new Microsoft.Maps.Point(e.getX(), e.getY());
        if (e.primitive.metadata.zadost) {
            
            this.props.onSelectZadost(e.primitive.metadata.zadost);
        }
    }

    componentDidMount() {
        Microsoft = window.Microsoft;
        var mapReference: string = '#mapa';
        this.map = new Microsoft.Maps.Map(mapReference, {
            credentials: "AuwVkZ9Hc2pmQ3iTCOwpcDZlY6DQI0I5MxEuPpZE68p--J-MxWGib3C_9YX7JXi2",
            mapTypeId: Microsoft.Maps.MapTypeId.aerial,                      
        });
        if (this.map) {            
            const config =
            {
                mercator: new Microsoft.Maps.TileSource({
                    uriConstructor: `${this.props.wmsUrl}/wms/GetImageQuadKey?quadKey={quadkey}&wmsId=1`,
                    minZoom: 13,
                    maxZoom: 22,
                })
            };
            this.layer = new Microsoft.Maps.Layer();
            if (this.layer) {
                this.map.layers.insert(this.layer);
            }

            var mapLayer = new Microsoft.Maps.TileLayer(config);
            this.map.layers.insert(mapLayer);
            if (this.props.zadosti) {
                this.renderZadosti(this.props.zadosti);
            }
            Microsoft.Maps.Events.addHandler(this.layer, 'click', this.mapclick.bind(this));
        }
    }

    componentDidUpdate(prevProps: IZadostiMapFormProps) {
        if (this.props.zadosti && this.props.zadosti != prevProps.zadosti) {
            this.renderZadosti(this.props.zadosti)
        }
    }

    private renderZadosti(zadosti: IZadostListItem[]) {
        if (this.layer) {
            this.layer.clear();
            const pins: Microsoft.Maps.Pushpin[] = [];

            for (let i = 0; i < zadosti.length; i++) {
                var pin = new Microsoft.Maps.Pushpin(new Microsoft.Maps.Location(zadosti[i].location.latitude, zadosti[i].location.longitude), {
                    color: '#106ebe'
                });
                pin.metadata = { zadost: zadosti[i] }
                pins.push(pin);
            }
            this.layer.setPrimitives(pins);
        }
    }


    render() {
        return <div style={{
            position: 'relative',
            minWidth: '100%',
            minHeight: '600px',
            height: '200px'
        }}>
            <div style={{ minHeight: "600px;", minWidth: 800 }} id='mapa' className={'react-bingmaps'}> </div>
       </div>
    }

}
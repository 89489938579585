import * as React from 'react';
import { IIconProps,IColumn, Selection, Panel, MarqueeSelection, DetailsList, ActionButton, Separator, Text, Label, Stack, DefaultButton, DetailsListLayoutMode } from '@fluentui/react';
import { IZadaniObjekt } from '../../../Types/IZadaniObjekt';
import { RuianInterfaceTypeEnum } from '../../../Types/Ruian/RuianInterfaceTypeEnum';
import { IParcela } from '../../../Types/Ruian/IParcela';

interface InfoPanelZadaniObjektyProps {
    items?: IZadaniObjekt[];
    active?: IZadaniObjekt;
    selectionPanelIsOpen: boolean;
    selectedItems?: IZadaniObjekt[];

    addToItems:(item?: IZadaniObjekt)=> void;
    setSelected: (item?: IZadaniObjekt[]) => void;
    removeSelected: (items?: IZadaniObjekt[]) => void;
    closePanel: () => void;

}

interface InfoPanelZadaniObjektyState {

}

const Delete: IIconProps = { iconName: 'Delete' };


export class InfoPanelZadaniObjekty extends React.Component<InfoPanelZadaniObjektyProps, InfoPanelZadaniObjektyState>
{
    private _selection: Selection;
    private _columns: IColumn[];

    constructor(props: InfoPanelZadaniObjektyProps) {
        super(props);


        this._selection = new Selection({
            onSelectionChanged: () => this._getSelectionDetails(),
        });

        this._columns = [
            { key: 'column1', name: 'Typ', fieldName: 'discriminator', minWidth: 100, maxWidth: 200, isResizable: true },
            { key: 'column2', name: 'Popis', fieldName: 'title', minWidth: 100, maxWidth: 200, isResizable: true },
        ];
    }

    private _getSelectionDetails() {
        const selection = this._selection.getSelection() as IZadaniObjekt[];
        this.props.setSelected(selection);
        console.log(selection);
    }

    getDruhCislovaniKodText(kod: number): string {
        switch (kod) {
            case 1: return "stavení parcela"
            case 2: return "pozemková parcela"
            default: return "neznámý typ"
        }
    }


    renderParcelaJSX(): JSX.Element | null {
        if (this.props.active && this.props.active.discriminator === RuianInterfaceTypeEnum.Parcela) {
            const data = this.props.active.data as IParcela;

            //Kontrola za už je objekt přidaný nebo ne
            var addbuttonDisable = false;           
            if (this.props.items) {
                for (var i = 0; i < this.props.items.length; i++) {
                    if (this.props.items[i].discriminator === RuianInterfaceTypeEnum.Parcela) {
                        if (this.props.items[i] && this.props.items[i].key == this.props.active.key) {
                            addbuttonDisable = true;
                            break;
                        }
                    }
                }
            }

            return (<Stack verticalAlign="start">
                <Label>Parcela</Label>
                <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 5 }}><Label>Typ</Label> <Text>{this.getDruhCislovaniKodText(data.druhCislovaniKod) + " " + data.kmenoveCislo + (data.pododdeleniCisla ? "/" + data.pododdeleniCisla.toString() : "")}</Text> </Stack>                
                <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 5 }}><Label>Katastr</Label><Text>{data.katastralniUzemi}</Text> </Stack>
                <Stack horizontal verticalAlign="center" horizontalAlign="center" tokens={{ childrenGap: 5 }} styles={{ root: { paddingTop: "15px" } }}>
                    <DefaultButton disabled={addbuttonDisable} iconProps={{ iconName: 'Add' }} text="Přidat do výběru" styles={{ root: { width: "200px" } }}
                        onClick={() => {
                            this.props.addToItems(this.props.active);                            
                        }}
                    />
                </Stack>
            </Stack>)
        }
        else
            return null;
    }

    renderPolygonJSX(): JSX.Element | null {
        if (this.props.active && this.props.active.discriminator === RuianInterfaceTypeEnum.Polygone) {
            const data = this.props.active.polygon;

            var addbuttonDisable = false;
            if (this.props.items) {
                for (var i = 0; i < this.props.items.length; i++) {
                    if (JSON.stringify(this.props.items[i].key) == this.props.active.key) {
                        addbuttonDisable = true;
                        break;
                    }
                }
            }

            return (<Stack verticalAlign="start">
                <Label>Polygon</Label>
                <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 5 }}><Label>Rozloha</Label><Text> {this.props.active.title}</Text> </Stack>
                <Stack horizontal verticalAlign="center" horizontalAlign="center" tokens={{ childrenGap: 5 }} styles={{ root: { paddingTop: "15px" } }}>
                    <DefaultButton disabled={addbuttonDisable} iconProps={{ iconName: 'Add' }} text="Přidat do výběru" styles={{ root: { width: "200px" } }}
                        onClick={() => {
                            this.props.addToItems(this.props.active);
                        }}
                    />
                </Stack>
            </Stack>)
        }
        else
            return null;
    }

    private _onItemInvoked = (item: IZadaniObjekt): void => {
        alert(`Item invoked: ${item.discriminator}`);
    };

    private _getKey(item: any, index?: number): string {
        return item.key;
    }

    render() {
        return (
            <Panel
                headerText="Území zájmu"
                isOpen={this.props.selectionPanelIsOpen}
                isBlocking={false}
                onDismiss={this.props.closePanel}
                closeButtonAriaLabel="Close">
                <div style={{ minHeight:135 }}>
                    {this.renderParcelaJSX()}
                    {this.renderPolygonJSX()}
                </div>
                <Separator />
                <p>Parcely / polygony pro vyjádření</p>

                <ActionButton text="Odstranit vybrané" iconProps={Delete} title="Odstraní vybrané polygony" ariaLabel="Odstraní vybrané polygony" disabled={!(this.props.selectedItems && this.props.selectedItems.length > 0)} checked={false}
                    onClick={() => {
                        if (this.props.selectedItems) {
                            this.props.removeSelected(this.props.selectedItems);
                        }
                    }} />
                <MarqueeSelection selection={this._selection}>
                    <DetailsList
                        items={this.props.items?this.props.items:[]}
                        columns={this._columns}
                        getKey={this._getKey}
                        setKey="set"
                        layoutMode={DetailsListLayoutMode.justified}
                        selection={this._selection}
                        selectionPreservedOnEmptyClick={true}
                        ariaLabelForSelectionColumn="Klikněte pro výber nebo zrušení výběru"
                        ariaLabelForSelectAllCheckbox="Klikněte pro výber všech položek nebo jeho zrušení"
                        checkButtonAriaLabel="Row checkbox"
                        onItemInvoked={this._onItemInvoked}
                    />
                </MarqueeSelection>


            </Panel>
        );
    }
}
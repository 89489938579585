import * as React from "react";
import { IColumn, MarqueeSelection, DetailsList, SelectionMode, DetailsListLayoutMode, Stack, IconButton, Dialog, DialogType, DialogContent, DialogFooter, PrimaryButton, DefaultButton, TextField, Modal, getTheme, mergeStyleSets, FontWeights, Link, MessageBar, MessageBarType } from "@fluentui/react"
import { IVyjadreniFile } from "../../../Types/Admin/IVyjadreniFile";
import { IVyjadreniApi } from "../../../Services/IVyjadreniApi";
import { VyjadreniAddFileForm } from './VyjadreniAddFileForm'
import { IVyjadreni } from "../../../Types/Admin/IVyjadreni";
import { IVyjadreniPrilohaTypEnum } from "../../../Types/Admin/IVyjadreniPrilohaTypEnum";
import { IVyjadreniPrilohaStavEnum } from "../../../Types/Admin/IVyjadreniPrilohaStavEnum";

interface IVyjadreniDetailFileListProps {
    vyjadreniId: number;
    vyjadreniApi: IVyjadreniApi;
    readonly: boolean;
}

const columns: IColumn[] = [
    {
        key: "nazev",
        name: "Název",
        fieldName: "nazev",
        minWidth: 200,
    },
    {
        key: "typ",
        name: "Typ",
        fieldName: "typ",
        minWidth: 150,
    },
    {
        key: 'editButton',
        name:'editButton',
        minWidth: 16,
        maxWidth: 16,
        isIconOnly:true
    },    
    {
        key: 'deleteButton',
        name: 'deleteButton',
        minWidth: 16,
        maxWidth: 16,
        isIconOnly: true
    },
    {
        key: 'downloadButton',
        name: 'downloadButton',
        minWidth: 16,
        maxWidth: 16,
        isIconOnly: true
    }

];

const theme = getTheme();
const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
    },
    header: [       
        theme.fonts.xLargePlus,
        {
            flex: '1 1 auto',
            borderTop: `4px solid ${theme.palette.themePrimary}`,
            color: theme.palette.neutralPrimary,
            display: 'flex',
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '12px 12px 14px 24px',
        },
    ],
    body: {
        flex: '4 4 auto',
        padding: '0 24px 24px 24px',
        overflowY: 'hidden',
        minWidth:400,
        selectors: {
             p:{ margin: '14px 0' },
            'p:first-child': { marginTop: 0 },
            'p:last-child': { marginBottom: 0 },
        },
    },
});

//this._selection = new Selection({
//    onSelectionChanged: () => {
//        this.setState({
//            selectionDetails: this._getSelectionDetails(),
//        });
//    },
//});

export const VyjadreniDetailFileList: React.FC<IVyjadreniDetailFileListProps> = (props) => {
    const [items, setItems] = React.useState<IVyjadreniFile[]>([]);
    const [isEditOpen, setIsEditOpen] = React.useState(false);
    const [selectedFile, setSelectedFile] = React.useState<IVyjadreniFile>();
    const [error, setError] = React.useState<string>();

    const getData = async()=>
    {
        const vyjadreni = await props.vyjadreniApi.getFiles(props.vyjadreniId);
        if (vyjadreni.isSuccess && vyjadreni.data) {
            setItems(vyjadreni.data);
        }
        else {

        }
    }

    React.useEffect(() => {
        getData();
    }, [props.vyjadreniId]);


    const editFile = (item?: IVyjadreniFile) => {
        if (item) {
            setSelectedFile(item as IVyjadreniFile);
            setIsEditOpen(true);
        }
    };

    const deteleFile = async (item?: IVyjadreniFile) => {
        if (item) {
            const r = window.confirm("Opravdu chcete odstravit vybraný soubor?");
            if (r) {
                const result = await props.vyjadreniApi.deleteFile(props.vyjadreniId, item.id);
                if (result.isSuccess) {
                    await getData();
                    setError(undefined)
                }
                else {
                    setError(result.message);
                }
            }
        }
    }

    const downloadFile = async (item?: IVyjadreniFile) => {
        if (item) {
            const result = await props.vyjadreniApi.getFile(props.vyjadreniId, item.id);
            if (result.isSuccess) {
                const url = window.URL.createObjectURL(result.data.blob);
                const link = document.createElement('a');
                link.href = url;
                link.target = "_blank"
                link.download = result.data.fileName;
                link.click();
                link.remove();
                setError(undefined)
            }
            else {
                setError(result.message);
            }
        }
    }

    const _getKey = (item: any, index?: number): string =>{
        return item.key;
    }

    const _renderItemColumn = (item?: IVyjadreniFile, index?: number, column?: IColumn) =>{
        if (column && item) {
            const fieldContent = item[column.fieldName as keyof IVyjadreniFile] as string;
            
            switch (column.key) {
                case 'typ':
                    switch (fieldContent as any as IVyjadreniPrilohaTypEnum) {
                        case IVyjadreniPrilohaTypEnum.EmailPriloha: return "Příloha emailu"
                        case IVyjadreniPrilohaTypEnum.OblastZajmu: return "Mapa oblasti zájmu"
                        case IVyjadreniPrilohaTypEnum.TrasyNahled: return "Mapa tras"
                        case IVyjadreniPrilohaTypEnum.TrasyNahledProjekt: return "Mapa plánovaných tras"
                        case IVyjadreniPrilohaTypEnum.Vyjadreni: return "Vyjaření"
                        default: return ""
                    }
                case 'editButton':
                    return (item.typ != IVyjadreniPrilohaTypEnum.Vyjadreni && item.typ != IVyjadreniPrilohaTypEnum.OblastZajmu ? <IconButton iconProps={{ iconName: "Edit" }} onClick={() => editFile(item)}></IconButton > : null)
                case 'downloadButton':
                    return <IconButton iconProps={{ iconName: "Download" }} onClick={() => downloadFile(item)}></IconButton>;
                case 'deleteButton':
                    return (item.typ != IVyjadreniPrilohaTypEnum.Vyjadreni && item.typ != IVyjadreniPrilohaTypEnum.OblastZajmu ? <IconButton iconProps={{ iconName: "Delete" }} onClick={() => deteleFile(item)}></IconButton> : null)

                default:
                    return <span>{fieldContent}</span>;
            }
        }
    }

    const insertFile = async (vyjadreniId: number, nazev: string, popis: string, typ: IVyjadreniPrilohaTypEnum, file: File) => {
        const result = await props.vyjadreniApi.addFile(vyjadreniId, nazev, popis, typ, file);
        if (result.isSuccess) {
            await getData();
            setIsEditOpen(false);
            setError(undefined);
        }
        else {
            setIsEditOpen(false);
            setError(result.message);
        }
    }

    const updateFile = async (vyjadreniId:number,id: number, nazev: string, popis: string, typ: IVyjadreniPrilohaTypEnum) => {
        const result = await props.vyjadreniApi.updateFile(vyjadreniId, id, nazev, popis, typ)
        if (result.isSuccess) {
            await getData();
            setIsEditOpen(false);
            setError(undefined)
        }
        else {
            setIsEditOpen(false);
            setError(result.message);
        }
    }

    const saveFile = async (id: number, nazev: string, popis: string, typ: IVyjadreniPrilohaTypEnum, file?: File) => {
        if (id === 0 && file) {
            await insertFile(props.vyjadreniId, nazev, popis, typ, file);            
        }
        else {
            await updateFile(props.vyjadreniId, id, nazev, popis, typ);
        }
    }

    const newFile = () => {
        const file: IVyjadreniFile = {
            vyjadresniId: props.vyjadreniId,
            id: 0,
            nazev: "",
            popis: "",
            stav: IVyjadreniPrilohaStavEnum.Rucne,
            typ: IVyjadreniPrilohaTypEnum.TrasyNahledProjekt
        }
        setSelectedFile(file);
        setIsEditOpen(true);
    }


    return (<Stack>

       
            {(error ? <MessageBar
                messageBarType={MessageBarType.error}
                isMultiline={false}
                onDismiss={() => setError(undefined)}
                dismissButtonAriaLabel="Close">
                {error}
        </MessageBar> : null)}
        {!props.readonly ? <IconButton iconProps={{ iconName: "add" }} onClick={newFile} /> : null}

            <DetailsList items={items}
                columns={columns}
                selectionMode={SelectionMode.single}
                getKey={_getKey}
                setKey="multiple"
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible={true}
                onRenderItemColumn={_renderItemColumn}
                //selection={this._selection}
                selectionPreservedOnEmptyClick={true}                
                enterModalSelectionOnTouch={true}
                checkButtonAriaLabel="select row"/>
         
        <Modal
            onDismiss={() => setIsEditOpen(false) }
            isOpen={isEditOpen}
            containerClassName={contentStyles.container} >
                <div className={contentStyles.header}>ÚPRAVA SOUBORU</div>
                <div className={contentStyles.body}>
                <Stack>
                    <VyjadreniAddFileForm file={selectedFile} onSave={saveFile}
                        onClose={() => {
                            setIsEditOpen(false)
                        }} />
                </Stack>
            </div>
        </Modal>
    </Stack>)
}
import * as React from 'react';
import { Selection, Text,Stack, TextField, Dropdown, Separator, ActionButton, MarqueeSelection, DetailsList, DetailsListLayoutMode, ComboBox, Checkbox, IComboBoxOption, IColumn, Icon, IDropdownOption, Toggle, ProgressIndicator } from '@fluentui/react';
import { getFileTypeIconProps, initializeFileTypeIcons } from '@uifabric/file-type-icons';
import { IPriloha } from '../../../Types/Zadost/IPriloha';
import { IUpresneniZadani } from '../../../Types/Zadost/IUpresneniZadani';
import { KeyValuePair } from '../../Validation';

initializeFileTypeIcons(undefined);


interface UpresneniZadostniFormProps {
    fileItems: IPriloha[];
    upresneni: IUpresneniZadani;
    uploading: boolean;
    validation: KeyValuePair;
    uploadFiles: (file: File[], callback: (progress: number, filename: string) => void) => void;
    deleteFiles: (files: IPriloha[]) => void;
    fieldChanged: (name: string, value: string | boolean) => void;
    disabled: boolean;

}

const duvodZadostiOptions: IComboBoxOption[] = [
    { key: 'B', text: 'Informace o existenci a poloze vedení' },
    { key: 'C', text: 'Územní souhlas, územní rozhodnutí' },
    { key: 'D', text: 'Stavební povolení nebo sloučené územní a stavební řízení' },
    { key: 'E', text: 'Ohlášení stavby' },
    { key: 'G', text: 'Změna užívání stavby' },
    { key: 'H', text: 'Havárie' },
    { key: 'J', text: 'Jiné (s textovým polem pro manuální vypsání účelu)' },
];

const ucelStavbyOptions: IComboBoxOption[] = [
    { key: 'B', text: 'Bytové domy' },
    { key: 'C', text: 'Rodinné domy, garáže' },
    { key: 'D', text: 'Administrativní budovy' },
    { key: 'E', text: 'Inženýrské sítě' },
    { key: 'G', text: 'Průmyslové zóny' },
    { key: 'H', text: 'Drobná stavební činnost' },
    { key: 'I', text: 'Pozemní komunikace' },
    { key: 'J', text: 'Výsadba zeleně' },
    { key: 'J1', text: 'Zemní práce, terenní' },
    { key: 'J2', text: 'Oplocení' }
];


const columns = [
    { key: 'column0', name: '', fieldName: 'ext', minWidth: 50, maxWidth: 50, isResizable: true },
    { key: 'column1', name: 'Název', fieldName: 'nazev', minWidth: 100, isResizable: true },
    { key: 'column2', name: 'Velikost', fieldName: 'size', minWidth: 100, maxWidth: 150, isResizable: true },
];

export const UpresneniZadostiForm: React.FC<UpresneniZadostniFormProps> = (props) => {
    const [prilohySelected, setPrilohySelected] = React.useState<IPriloha[]>([]);
    const [uploadProgress, setUploadProgress] = React.useState(0);
    const [uploadFileName, setUploadFileName] = React.useState("");
    

    React.useEffect(() => {
        if (!props.uploading) {
            setUploadProgress(0);
            setUploadFileName("");
        }
    }, [props.uploading]);

    const _renderItemColumn = (item?: IPriloha, index?: number, column?: IColumn) => {
        if (column && index != undefined && item) {
            const fieldContent = item[column.fieldName as keyof IPriloha] as string;

            switch (column.key) {
                case 'column0':
                    return <Icon {...getFileTypeIconProps({ extension: fieldContent.toLowerCase(), size: 16, imageFileType: 'png' })} />
                default:
                    return <span> {fieldContent}</span >;
            }
        }
        else {
            return null
        }
    }

    const _selection: Selection = new Selection({
        onSelectionChanged: () => setPrilohySelected(_selection.getSelection() as IPriloha[])
    });

    const fileButtonClick = () => {
        if (document) {
            document.getElementById('hiddenFileInput')?.click();
        }
    }

    const onChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event && event.target && event.target.files) {
            const files = event.target.files;
            const newaFiles: File[] = []
            for (var i = 0; i < event.target.files.length; i++) {
                newaFiles.push(event.target.files[i]);
            }
            props.uploadFiles(newaFiles, (progress: number, filename: string) => {
                setUploadFileName(filename);
                setUploadProgress(progress)
            });
        }
    }

    const duvodZadostiOnChage = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption | undefined): void => {
        props.fieldChanged("duvodZadosti", item ? item.key.toString() : "")
    }
    const UcelStavbyOnChaged = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption | undefined): void => {
        props.fieldChanged("ucelStavby", item? item.key.toString():"")
    }
    console.log(props.validation);

    return (
        <Stack tokens={{ childrenGap: 15 }} styles={{ root: { paddingTop: 25 } }}>
            <TextField name="nazevStavby" key="nazevStavby" label="Název stavby" value={props.upresneni.nazevStavby} onChange={(event: any, value: string | undefined) => props.fieldChanged("nazevStavby", value ? value : "")} />
            <Dropdown
                selectedKey={props.upresneni.duvodZadosti}
                placeholder="Vyberte důvod žádosti o vyjídření"
                key="duvodZadosti"
                label="Důvod žádosti vyjádření"
                required
                errorMessage={props.validation["duvodZadosti"] && !props.validation["duvodZadosti"].isValid ? props.validation["duvodZadosti"].validationError : ""}
                options={duvodZadostiOptions}
                onChange={duvodZadostiOnChage}
            />
            <TextField name="doplnujiciInfo" label="Doplňující informace pro zpracovatele Vyjádření k vybranému Důvodu Vyjádření" value={props.upresneni.doplnujiciInfo} onChange={(event: any, value: string | undefined) => props.fieldChanged("doplnujiciInfo", value ? value : "")} />

            <Dropdown
                selectedKey={props.upresneni.ucelStavby}
                required
                label="Účel Stavby"
                placeholder="Vyberte účel stavby"
                key="ucelStavby"
                options={ucelStavbyOptions}
                onChange={UcelStavbyOnChaged}
            />

            <input id="hiddenFileInput" multiple type="file" onChange={onChange} style={{ display: "none" }} />

            <Separator>Přílohy</Separator>
            <Text>
                Můžete vložit tyto přílohy žádosti:
                     <ul>
                    <li>Koordinační situaci stavby se zakreslenou polohou Sítě elektronických komunikací (dále jen SEK) společnosti PODA a.s. (formát PDF, JPG) Pokud nemáte polohu SEK společnosti PODA a.s. zakreslenu, zadejte prosím jako Důvod Vyjádření "Informace o poloze sítě". Obdržíte Situační výkres s polohou SEK společnosti PODA</li>
                    <li>Technickou zprávu, případně jiné dokumenty související s požadovanými podklady (formát DOC, DOCX). </li>
                    <li>Nevložení potřebných podkladů, vložení podkladů v jiném než povoleném formátu a/nebo vložení neúplných, nečitelných či poškozených souborů zakládá společnosti PODA a.s. právo vyzvat žadatele k doplnění žádosti.</li>
                </ul>
            </Text>
            {(props.uploading ?
                <ProgressIndicator label="Nahrávání souborů" description={uploadFileName} percentComplete={uploadProgress / 100.0} />
                : null)
            }

            <Stack horizontal>
                <ActionButton iconProps={{
                    iconName: "add"
                }} allowDisabledFocus disabled={props.uploading} checked={false} onClick={fileButtonClick}>
                    Přidej soubor
                    </ActionButton>
                <ActionButton  iconProps={{
                    iconName: "Delete"
                }} allowDisabledFocus disabled={(prilohySelected ? (prilohySelected.length == 0) && props.uploading : true)} checked={false} onClick={() => { props.deleteFiles(prilohySelected) }}>
                    Odeber vybrané
                </ActionButton>
            </Stack>
            
                <DetailsList
                    items={props.fileItems}
                    columns={columns}
                    setKey="set"
                    onRenderItemColumn={_renderItemColumn}
                    layoutMode={DetailsListLayoutMode.justified}
                    selection={_selection} 
                    selectionPreservedOnEmptyClick={true}
                    ariaLabelForSelectionColumn="Toggle selection"
                    ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                    checkButtonAriaLabel="Row checkbox"/>
            <Separator />
            <Toggle disabled={props.disabled}
                checked={props.upresneni.dgn}
                label={"Mám zájem o datový soubor trasy SEK ve vyznačeném Zájmovém území ve formátu *.dgn"}
                onChange={(event: any, checked: boolean | undefined) => {
                    props.fieldChanged("dgn", checked ? checked:false)
                }}/>

            <Toggle disabled={props.disabled}
                checked={props.upresneni.podminkyPripojeni}
                label={"Žádám o stanovení podmínek připojení na SEK ve vlastnictví PODA a.s."}
                onChange={(event: any, checked: boolean | undefined) => {
                    props.fieldChanged("podminkyPripojeni", checked ? checked : false)
                }}
            />
            <div>
            <Toggle disabled={props.disabled}
                checked={props.upresneni.postou}
                label={"Žádám o zaslání vyjádření poštou."}
                onChange={(event: any, checked: boolean | undefined) => {
                    props.fieldChanged("postou", checked ? checked : false)
                }}
            />
                {props.upresneni.postou ?
                    <span style={{ color: 'red', fontStyle:'italic' }}>Zaslání bude účtováno dle platného ceníku</span>
             : null}
            </div>
        </Stack>
    );
}
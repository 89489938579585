import * as React from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { PrimaryButton, Stack, DefaultButton } from '@fluentui/react';
import { MutableRefObject } from 'react';

interface ITextEditorProps {
    text: string;
    saveText: (text: string) => void;
    close: () => void;
}

export const TextEditor: React.FC<ITextEditorProps> = (props) => {
    const editorRef = React.useRef<any>(null);

    const save = () => {
        if (editorRef && editorRef.current) {
            const editor = editorRef.current as any;
            props.saveText(editor.getContent());
        }
    };

    React.useEffect(() => {
       
    });


    return (<><Editor
        apiKey="w24vi2qvgbot8i5s4cdehkcra5nygyk2oqo02t78ofll0xtl"
        onInit={(evt, editor) => {
            if (editorRef != null) {
                editorRef.current = editor;
            }
         }
        }
        initialValue={props.text}
        init={{
            height: 600,
            menubar: false,
            plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help wordcount'
            ],
            toolbar: 'undo redo | formatselect | ' +
                'bold italic backcolor forecolor | alignleft aligncenter ' +
                'alignright alignjustify | bullist numlist outdent indent | ' +
                'removeformat | help',
            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
        }}
    />
        <Stack horizontal horizontalAlign="center" tokens={{ padding: 10 }}>
            <DefaultButton primary onClick={() => save()}>Uložit</DefaultButton >
            <DefaultButton onClick={props.close}>Zavřít</DefaultButton >
        </Stack>
        </>
    );
}
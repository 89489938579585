import * as React from 'react';
import { Stack, IconButton } from '@fluentui/react';
import SearchAutocomplete from '../../basic/SearchAutocomplete';
import { ISuggestionItem } from '../../basic/AutocompleteTypes';

interface ISearchPanelProps {
    adresaSearch: (text: string) => Promise<ISuggestionItem[]>,
    katastrSearch: (text: string) => Promise<ISuggestionItem[]>,
    parcelaSearch: (text: string) => Promise<ISuggestionItem[]>,

    adresaSeleted: (item: ISuggestionItem) => void
    katastrSeleted: (item: ISuggestionItem) => void
    parcelaSeleted: (item: ISuggestionItem) => void

    adresaCleared: () => void
    katastrCleared: () => void
    parcelaCleared: () => void

    parcelaClearFilter: () => void
    parcelaFiltrInfo: string

    showHelp: () => void;

}

export const SearchPanel: React.FC<ISearchPanelProps> = (props) => {

    return (<Stack tokens={{ childrenGap: 5 }} horizontal wrap>
        <SearchAutocomplete
            key="VyhledaniAdresy"            
            id="VyhledaniAdresy"            
            placeholder="Zadejte adresu"
            onSearch={props.adresaSearch}
            onItemSelectedCallback={props.adresaSeleted}
            onClear={props.adresaCleared} />

        <Stack tokens={{ childrenGap: 5 }} horizontal styles={{ root: { paddingTop: 0, marginTop: 0 } }}>
            <SearchAutocomplete
                id={"VyhledaniKatastraniUzemi"}
                placeholder="Karastrální území"
                onSearch={props.katastrSearch}
                onItemSelectedCallback={props.katastrSeleted}
                onClear={props.katastrCleared} />

            <SearchAutocomplete
                id="VyhledaniParcely"
                placeholder="Číslo parcely, které chcete vyhledat"
                onSearch={props.parcelaSearch}
                filtrInfo={props.parcelaFiltrInfo}
                onClear={props.parcelaCleared}
                onClearFilter={props.parcelaClearFilter}
                onItemSelectedCallback={props.parcelaSeleted} />
        </Stack>
        <Stack grow={2}>
            <Stack.Item align="end" grow={1}>
                <IconButton onClick={props.showHelp} title="Zobrazí nápovědu" iconProps={{ iconName: "help" }} />
            </Stack.Item>
        </Stack>
        
    </Stack>);
}
    
import * as React from 'react';
import { connect } from "react-redux";
import {Text, Link, ActionButton, IIconProps, getTheme, CommandBarButton, Stack, ITheme, registerIcons, FontIcon, mergeStyles, CommandButton, Callout, mergeStyleSets, FontWeights, IStackItemStyles, DefaultPalette, Button, DefaultButton, Label, FontSizes} from "@fluentui/react";
import authService from '../../api-authorization/AuthorizeService';
import { ApplicationPaths } from '../../api-authorization/ApiAuthorizationConstants';
 
import { IAppStore } from '../../../store/AppStore';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { push } from 'connected-react-router';
 
 
import register from '../../../registerServiceWorker';
import Axios from 'axios';
import { useBoolean } from '@uifabric/react-hooks';
import { IUser } from '../../../Types/IUser';
import { SetUser } from '../../../store/Auth/actions';

export interface ILogoutButtonState {
    IsCallOutVisible: boolean;   
}

export interface ILogoutButtonProps {
    user?: IUser,
    push: (url: string) => void;
    setUser: (user: IUser) => void;
    isAuthenticated: boolean;
}


registerIcons({
    icons: {
        'logout': (<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 64' fill='#106ebe' stroke='#106ebe'>
            <circle className='mectrl_stroke' cx='32' cy='32' r='30.25' stroke-width='1.5' />
            <g className='mectrl_stroke' stroke-width='2.5' transform='matrix(.9 0 0 .9 10.4 10.4)' fill="white" stroke='#106ebe'>
                <rect x='13.3' y='12.3' width='21.4' height='28.5' rx='.6' ry='.6'/>
                <circle cy='25.4' cx='24' r='3.6' /><path d='M18 35a1 1 0 1112 0'/>
            </g>
            <g className='mectrl_fill' stroke='none'>
                <path d='M36.68 14h2.34l-3.24 6.75h-2.43zM24.89 14h2.43l5.58 11.25a1.046 1.046 0 01-1.791 1.08l-.549-1.08z' stroke="white" fill="white" />
            </g>
            
        </svg>
        ),
        'login': (<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 64' stroke='#106ebe'>
            <g className='mectrl_stroke' stroke-width='1.9' fill='none'>
                <circle cx='32' cy='32' r='30.25' />
                <g transform='matrix(1.1 0 0 1.1 8.8 5.61)'>
                    <circle className='mectrl_stroke' cx='20' cy='16' r='7' />
                    <path className='mectrl_stroke' d='M30 35h10m-5-5v10M30.833 32.09A11 11 0 009 34' />
                </g>
            </g>
        </svg>)
        
    }
});

const loginIcon: IIconProps = { iconName: 'login' };
const loggedinIcon: IIconProps = { iconName: 'Contact' };

const theme = getTheme();

const styles = mergeStyleSets({
    buttonArea: {
        verticalAlign: 'top',
        display: 'inline-block',
        textAlign: 'center',               
    },
    callout: {
        Width: 320        
    },
    header: {
        paddingBottom:12
    },
    title: [
        theme.fonts.xLarge,
        {
            margin: 0,
            fontWeight: FontWeights.semilight,
        },
    ],
    inner: {
        height: '100%',
        padding: '0 24px 20px',
    },
    actions: {
        position: 'relative',
        marginTop: 20,
        width: '100%',
        whiteSpace: 'nowrap',
    },
    subtext: [
        theme.fonts.small,
        {
            margin: 0,
            fontWeight: FontWeights.semilight,
        },
    ],
    link: [
        theme.fonts.medium,
        {
            color: theme.palette.neutralPrimary,
        },
    ],
});

class LoginButton extends React.Component<ILogoutButtonProps, ILogoutButtonState>
{
    private _subscription: number = -1;
    private theme: ITheme;


    constructor(props: ILogoutButtonProps) {
        super(props);
        this.theme = getTheme();
        this.state = {            
            IsCallOutVisible:false
        };
    }

    componentDidMount() {
        this.theme = getTheme();
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }

    async populateState() {

        const isAuthenticated = await authService.isAuthenticated();
        const user = await authService.getUser()
        const token = await authService.getAccessToken();
        console.log("populateState");
        console.log(user);
        this.props.setUser({            
            surname: user && user.family_name,
            givenName: user && user.given_name,
            email: user?.name,
            token: token,
            role: user && user.role
        })
    }

    render() {
        if (this.props.isAuthenticated && this.props.user) {
            const profilePath = `${ApplicationPaths.Profile}`;
            const logoutPath = `${ApplicationPaths.LogOut}`;
            return this.authenticatedView(this.props.user.givenName, this.props.user.surname, this.props.user.email, profilePath, logoutPath);
        }
        else {
            const registerPath = `${ApplicationPaths.Register}`;
            const loginPath = `${ApplicationPaths.Login}`;
            return this.anonymouseMenu(registerPath, loginPath);
        }
    }

    private authenticatedView(givenname: string | null | undefined, surname: string | null | undefined, email: string | undefined | null, profilePath: string, logoutPath: string): React.ReactNode {
          
        const stackItemStyles: IStackItemStyles = {
            root: {
                alignItems: 'center',
                background: DefaultPalette.neutralPrimary,
                color: DefaultPalette.white,                
                maxWidth:88,
                justifyContent: 'center',
            },
        };
        const stackItemStyles1: IStackItemStyles = {
            root: {
                alignItems: 'center',
                background: DefaultPalette.white,
                color: DefaultPalette.white,
                minWidth:182,
                justifyContent: 'center',
                paddingLeft:10
            },
        }; 

        const theme = getTheme();

        return (<div style={{ minWidth: 40, height: 40, padding: 10, display: "inline-flex", float: "right", verticalAlign: "bottom" }}>
            <div className={styles.buttonArea}>
                <ActionButton onClick={() => { this.setState({ IsCallOutVisible: true }) }}
                    iconProps={{ iconName: "logout", styles: { root: { width: 34, height: 34, lineHeight: 34 } } }}
                    styles={{
                        flexContainer: {
                            flexDirection: 'row-reverse'
                        },
                        root: { height: 40 }
                    }}>
                    {givenname}
                </ActionButton>
            </div>
            {this.state.IsCallOutVisible ?
                <Callout
                    className={styles.callout}
                    ariaLabelledBy={"labelId"}
                    ariaDescribedBy={"descriptionId"}
                    role="alertdialog"
                    gapSpace={0}
                    target={`.${styles.buttonArea}`}
                    onDismiss={() => { this.setState({ IsCallOutVisible:false }) }}
                    setInitialFocus>
                    <Stack horizontalAlign="end" className={styles.header}>
                        <DefaultButton text="Odhlásit" onClick={() => { this.props.push(logoutPath) }} styles={{ root: { border: 0 }, label: { fontWeight: 400 }, textContainer: { fontWeight: 400 } }} />
                    </Stack>
                    <Stack horizontal styles={{ root: { marginTop: 30, marginBottom: 30, paddingLeft: 20, paddingRight: 20, paddingBottom: 20 } }} >
                        <Stack.Item styles={stackItemStyles} align="start">
                            <FontIcon iconName="logout" style={{ width: 88, backgroundColor: 'white' }} />
                        </Stack.Item>
                        <Stack.Item styles={stackItemStyles1} align="start">
                            <Stack>
                                <Label style={{ fontWeight: 700, fontSize: theme.fonts.large.fontSize }}>{givenname + " " + surname}</Label>
                                <Label style={{ fontWeight: 400, fontSize: theme.fonts.medium.fontSize }}>{email}</Label>

                                <Link styles={{ root: { paddingTop: 10 } }} onClick={() => this.props.push(profilePath)}>Změnit nastavení profilu</Link>
                            </Stack>
                        </Stack.Item>
                    </Stack>
                </Callout>
                : null}
        </div>)
    }

    private anonymouseMenu(registerPath: string, loginPath: string): React.ReactNode {
        const iconClass = mergeStyles({
            fontSize: 40,
            height: 40,
            width: 40,
            padding:10,
            margin: '0',
        });
        return (
            <div style={{ minWidth: 40, height: 40, padding: 10, display: "inline-flex", float: "right", verticalAlign: "bottom" }}>
                <ActionButton onClick={() => this.props.push(loginPath)}
                    iconProps={{ iconName: "login", styles: { root: { width: 40, height: 40, lineHeight: 40 } } }}
                    styles={{
                        flexContainer: {
                            flexDirection: 'row-reverse'
                        },
                        root: { height: 40 }
                    }}>
                    Přihlášení
                </ActionButton>
            </div>
        );
    }

    private _register() {
        this.props.push(ApplicationPaths.Register);
    }

    _profile() {
        this.props.push(ApplicationPaths.Profile);
    }


}

export const StoreToLoginButtonState = (state: IAppStore) => (
 {
    user: state.auth?.user,
    isAuthenticated: state.auth?.isAuthenticated
});

export const dispatchToLoginButtonToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
        push: (url: string) => dispatch(push(url)),
        setUser: (user: IUser) => dispatch(SetUser(user))
    };
};

export default connect(StoreToLoginButtonState, dispatchToLoginButtonToProps)(LoginButton)

import * as React from 'react';
import { Stack, TextField, IComboBoxOption, Separator, DetailsList, Toggle, DetailsListLayoutMode, IColumn, Icon, IconButton, SelectionMode, PrimaryButton, Modal, FontWeights, mergeStyleSets, getTheme } from '@fluentui/react';
import { UpresneniZadostiForm } from '../../zadost/UpresneniZadosti/UpresneniZadostiForm';
import { getFileTypeIconProps, initializeFileTypeIcons } from '@uifabric/file-type-icons';

import { IPriloha } from '../../../Types/Zadost/IPriloha';
import { IUpresneniZadani } from '../../../Types/Zadost/IUpresneniZadani';
import { IZadaniApi } from '../../../Services/IZadaniApi';
import { IZadostListItem } from '../../../Types/Admin/ZadostListItem';
import { IApiError } from '../../../Types/IApiError';
import { useId, useBoolean } from '@uifabric/react-hooks';
import ZadaniDetailMapForm from './ZadaniDetailMap';
import { IUser } from '../../../Types/IUser';


initializeFileTypeIcons(undefined);


interface IZadaniDetailFormProps {
    zadaniApi: IZadaniApi;
    zadost: IZadostListItem;
    user: IUser | undefined;
}

const duvodZadostiOptions: IComboBoxOption[] = [
    { key: 'B', text: 'Informace o existenci a poloze vedení' },
    { key: 'C', text: 'Územní souhlas, územní rozhodnutí' },
    { key: 'D', text: 'Stavební povolení nebo sloučené územní a stavební řízení' },
    { key: 'E', text: 'Ohlášení stavby' },
    { key: 'G', text: 'Změna užívání stavby' },
    { key: 'H', text: 'Havárie' },
    { key: 'J', text: 'Jiné (s textovým polem pro manuální vypsání účelu)' },
];

const ucelStavbyOptions: IComboBoxOption[] = [
    { key: 'B', text: 'Bytové domy' },
    { key: 'C', text: 'Rodinné domy, garáže' },
    { key: 'D', text: 'Administrativní budovy' },
    { key: 'E', text: 'Inženýrské sítě' },
    { key: 'G', text: 'Průmyslové zony' },
    { key: 'H', text: 'Drobná stavební činnost' },
    { key: 'I', text: 'Pozemní komunikace' },
    { key: 'J', text: 'Výsadba zeleně' },
    { key: 'J1', text: 'Zepmní práce, teréní úpravy' },
    { key: 'J2', text: 'Oplocení' }
];

const columns = [
    { key: 'column0', name: '', fieldName: 'ext', minWidth: 50, maxWidth: 50, isResizable: true },
    { key: 'column1', name: 'Název', fieldName: 'nazev', minWidth: 100, isResizable: true },    
    {
        key: 'downloadButton',
        name: 'downloadButton',
        minWidth: 16,
        maxWidth: 16,
        isIconOnly: true
    }
];

export const ZadaniDetailForm: React.FC<IZadaniDetailFormProps> = (props) => {
    const [upresneniZadosti, setUpresneniZadosti] = React.useState<IUpresneniZadani>()
    const [zadaniFiles, setZadaniFiles] = React.useState<IPriloha[]>([])    
    const getData = async (zadostId: number) => {
        const upresneni = await props.zadaniApi.getUpresneniZadani(zadostId, "");
        if (!(upresneni as IApiError).messageId) {
            setUpresneniZadosti(upresneni as IUpresneniZadani);
            const files = await props.zadaniApi.getFiles(zadostId, "");
            if (files.isSuccess && files.data) {
                setZadaniFiles(files.data.map((item: IPriloha) => {
                    item.ext = item.nazev.substr(item.nazev.lastIndexOf('.') + 1);
                    return item;
                }));
            }
        }
        else {

        }
    }

    const getDuvod = (duvodKey: string): string => {
        const duvod = duvodZadostiOptions.find(item => item.key === duvodKey);
        return (duvod ? duvod.text : "")
    }

    const getUcel = (ucelKey: string): string => {
        const ucel = ucelStavbyOptions.find(item => item.key === ucelKey);
        return (ucel ? ucel.text : "")
    }



    const downloadFile = async (item?: IPriloha) => {
        if (item) {
            const result = await props.zadaniApi.getFile(props.zadost.id,item.id,"");
            if (result.isSuccess) {
                const url = window.URL.createObjectURL(result.data.blob);
                const link = document.createElement('a');
                link.href = url;                
                link.setAttribute("download", result.data.fileName);
                link.click();
                document.body.appendChild(link);
                link.remove();
               /* setError(undefined)*/
            }
            else {
                /*setError(result.message);*/
            }
        }
    }

    const _renderItemColumn = (item?: any, index?: number, column?: IColumn) => {
        if (column && index != undefined && item) {
            const fieldContent = item[column.fieldName as keyof IPriloha] as string;

            switch (column.key) {
                case 'column0':
                    return <Icon {...getFileTypeIconProps({ extension: fieldContent.toLowerCase(), size: 16, imageFileType: 'png' })} />
                case 'downloadButton':
                    return <IconButton iconProps={{ iconName: "Download" }} onClick={() => downloadFile(item)}></IconButton>;
                default:
                    return <span> {fieldContent}</span >;
            }
        }
        else {
            return null
        }
    }

    React.useEffect(() => {
        getData(props.zadost.id);
    }, [props.zadost]);

    const titleId = useId('title');
    const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);
    const theme = getTheme();
    const contentStyles = mergeStyleSets({
        container: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
        },
        header: [           
            theme.fonts.xLargePlus,
            {
                flex: '1 1 auto',
                borderTop: `4px solid ${theme.palette.themePrimary}`,
                color: theme.palette.neutralPrimary,
                display: 'flex',
                alignItems: 'center',
                fontWeight: FontWeights.semibold,
                padding: '12px 12px 14px 24px',
            },
        ],
        body: {
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            overflowY: 'hidden',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
    });

 

    return (<Stack>
        {upresneniZadosti ?
           
            <Stack tokens={{ childrenGap: 15 }} styles={{ root: { paddingTop: 25 } }}>
                <TextField readOnly name="nazevStavby" key="nazevStavby" label="Název stavby" value={upresneniZadosti.nazevStavby} />
                <TextField readOnly name="duvodZadosti" key="duvodZadosti" label="Důvod žádosti" value={getDuvod(upresneniZadosti.duvodZadosti)} />
                <TextField readOnly name="doplnujiciInfo" label="Doplňující informace" value={upresneniZadosti.doplnujiciInfo} />
                <TextField readOnly name="ucelStavby" key="ucelStavby" label="Účel Stavby" value={getUcel(upresneniZadosti.ucelStavby)} />
                <Separator>Přílohy</Separator>

                <DetailsList
                    items={zadaniFiles}
                    columns={columns}
                    setKey="set"
                    onRenderItemColumn={_renderItemColumn}
                    layoutMode={DetailsListLayoutMode.justified}
                    selectionMode={SelectionMode.none}
                    selectionPreservedOnEmptyClick={true} />
                <Separator />
                <Toggle disabled
                    checked={upresneniZadosti.dgn}
                    label={"SEK ve formátu *.dgn"}/>

                <Toggle disabled 
                    checked={upresneniZadosti.podminkyPripojeni}
                    label={"Stanovení podmínek připojení na SEK"}
                />
                <div>
                    <Toggle disabled 
                        checked={upresneniZadosti.postou}
                        label={"Žádám o zaslání vyjádření poštou."}/>
                </div>
                {(props.user && props.user.role && props.user.role.indexOf("admin_write") != -1) ? <PrimaryButton onClick={showModal} text="Zobrazit na mapě" /> : null}
            </Stack>
            : null}
        {(props.user && props.user.role && props.user.role.indexOf("admin_write") != -1)  ?
            <Modal
                titleAriaId={titleId}
                isOpen={isModalOpen}
                onDismiss={hideModal}
                isBlocking={false}
                containerClassName={contentStyles.container}>
                {isModalOpen ?
                    <ZadaniDetailMapForm wmsUrl="" zadaniApi={props.zadaniApi} zadaniId={props.zadost.id} /> : null
                }
            </Modal>
            : null}

    </Stack>)
}
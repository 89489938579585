import { FunctionComponent } from "react";
import { Text,Selection, Stack, Panel, Separator, TextField, ComboBox, SearchBox, KeyCodes, MarqueeSelection, DetailsList, DetailsListLayoutMode, IColumn, ICssInput, IconButton, IIconProps, TooltipHost, ITooltipHostStyles, Label, Button, DefaultButton, ActionButton, TeachingBubble, IButtonProps, PrimaryButton } from "@fluentui/react";
import * as React from "react";

import { IParcela } from "../../../Types/Ruian/IParcela";
import { IAdresa } from "../../../Types/Ruian/IAdresa";
import { IKatastr } from "../../../Types/Ruian/IKatastr";

import { IAppStore } from "../../../store/AppStore";

import { ISuggestionItem } from '../../basic/AutocompleteTypes'
import { ICoordinate } from "../../../Types/ICoordinate";

import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { addUzemi } from "../../../store/zadost/actions";
import { SearchPanel } from "./SearchPanel";
import { IRuianApi } from "../../../Services/IRuianApi";
import { Map, mapStates } from "./Map";
import { IConfiguration } from "../../../Types/IConfiguration";
import { IZadaniObjekt } from  '../../../Types/IZadaniObjekt';
import { RuianInterfaceTypeEnum } from "../../../Types/Ruian/RuianInterfaceTypeEnum";
import { InfoPanelZadaniObjekty } from "./InfoPanelZadaniObjekty";
import { IPolygone } from "../../../Types/IPolygone";


export interface IZajmoveUzemiProps {
    back: () => void;
    configuration: IConfiguration,
    parcely: IParcela[];
    polygones: IPolygone[];
    saveUzemi: (parcely: IParcela[], polygone: IPolygone[]) => void;
    ruianApi: IRuianApi
}


export interface IZajmoveUzemiState {
    adresa?: IAdresa;
    katastr?: IKatastr;

    /**
     * Režim mapy
     * */
    mapState: mapStates;

    /**
     * Aktuálně zadávaný objekt. 
     * Vyhledaná adresa, parcela, katastrální úřad
     * */
    active?: IZadaniObjekt;

    /**
     * Příznak zda je otevřený panel s seznamem zadaných ojektů
     * 
     * */
    selectionPanelIsOpen: boolean;

    /*
     * Všechny objekty zadané ve zadání žádosti vyjádření
     * */
    items: IZadaniObjekt[];

    /*
     * Vybrané objekty v listboxu
     * */
    selectedItems?: IZadaniObjekt[];

    mapZoom: number;
    mapCenter: ICoordinate;

    toggleTeachingBubbleVisible: number;
    isValid: boolean;
}

var Microsoft: any = window.Microsoft;
const CheckList: IIconProps = { iconName: 'CheckList' };
const Section: IIconProps = { iconName: 'Section' };

class ZajmoveUzemiPage extends React.Component<IZajmoveUzemiProps, IZajmoveUzemiState>
{

    hostStyles: Partial<ITooltipHostStyles> = { root: { display: 'inline-block' } };

    constructor(props: IZajmoveUzemiProps) {
        super(props);
        this.state = {
            isValid: false,
            selectionPanelIsOpen: false,
            items: [],
            mapState: mapStates.view,
            mapZoom: 15,
            toggleTeachingBubbleVisible: 0,
            mapCenter: { latitude: 49.71474327508265, longitude: 16.265422710391093,elevation:0}
        };
    }

    componentDidMount() {
        Microsoft = window.Microsoft;
        console.log("componentDidMount");
        const oblasti = this.loadConfiguration(this.props.parcely, this.props.polygones);
        this.setState({
            items: oblasti,
            selectionPanelIsOpen: oblasti.length > 0
        }, () => {
                if (this.state.items.length > 0)
                    this.hideTeachingBubble();
        });        
    }

    private loadConfiguration(parcely: IParcela[], polygony: IPolygone[]) {
        console.log("loadConfiguration parcely:" + parcely.length)
        console.log("loadConfiguration polygony:" + polygony.length)

        const oblasti: IZadaniObjekt[] = [];
        if (parcely) {
            for (var i = 0; i < parcely.length; i++) {
                const data = parcely[i];
                const o: IZadaniObjekt = {
                    key: data.id.toString(),
                    discriminator: RuianInterfaceTypeEnum.Parcela,
                    title: data.kmenoveCislo.toString() + (data.pododdeleniCisla ? "/" + data.pododdeleniCisla.toString() : ""),
                    polygon: data.hranice,
                    point: data.definicniBod,
                    data: data
                };
                oblasti.push(o);
            }
        }
        if (polygony) {
            console.log("set state ZajmoveUzemiPage polygones:" + this.props.polygones.length)
            for (var i = 0; i < polygony.length; i++) {
                const data = polygony[i];
                var exteriorRing = data.hranice.map((item: ICoordinate, index: number) => {
                    const location = new Microsoft.Maps.Location(item.latitude, item.longitude);
                    return location;
                });
                var p = new Microsoft.Maps.Polygon(exteriorRing, {
                    fillColor: 'transparent',
                    strokeColor: 'red',
                    strokeThickness: 2,
                    strokeDashArray: [1, 2],
                });
                const area = Microsoft.Maps.SpatialMath.Geometry.area(p, Microsoft.Maps.SpatialMath.AreaUnits.SquareMeters) as number;
                const o: IZadaniObjekt = {
                    key: this.makeId(),
                    discriminator: RuianInterfaceTypeEnum.Polygone,
                    title: "Polygon " + (area).toFixed() + " m2",
                    polygon: data.hranice,
                    point: data.hranice[0],
                };
                oblasti.push(o);
            }
        }
        return oblasti;
    }

    componentDidUpdate(prevProps: IZajmoveUzemiProps, prevState: IZajmoveUzemiState) {
        if (this.props.parcely != prevProps.parcely || this.props.polygones != prevProps.polygones) {            
            const oblasti = this.loadConfiguration(this.props.parcely, this.props.polygones);
            this.setState({
                items: oblasti,
                selectionPanelIsOpen: oblasti.length > 0
            }, () => {
                if (this.state.items.length > 0)
                    this.hideTeachingBubble();                
            });
        }
        if (this.state.items != prevState.items) {
            this.setState({ isValid: this.state.items.length > 0 })
        }
    }

    componentWillUnmount() {    
    }


    getDruhCislovaniKodText(kod: number): string {
        switch (kod) {
            case 1: return "stavení parcela"
            case 2: return "pozemková parcela"
            default: return "neznámý typ"
        }
    }

    /**
     * Vyhledá parcel podle souřadnic
     * @param location 
     */
    async findParcelById(location: ICoordinate) {
        const parcel = await this.props.ruianApi.getParcelByLocation(location);
        if (parcel) {
            const parcela: IZadaniObjekt = {
                key: parcel.id.toString(),
                title: parcel.kmenoveCislo.toString() + (parcel.pododdeleniCisla ? "/" + parcel.pododdeleniCisla.toString() : ""),
                polygon: parcel.hranice,
                data: parcel,
                point: parcel.definicniBod,
                discriminator: RuianInterfaceTypeEnum.Parcela
            }
            this.setState({ active: parcela, selectionPanelIsOpen: true })
        }
    }

    async onSearchAddress(text: string): Promise<ISuggestionItem[]> {
        return await this.props.ruianApi.getAddressSugestion(text);
    }

    async onSearchKatastr(text: string): Promise<ISuggestionItem[]> {
        return await this.props.ruianApi.getKatastrSugestion(text);
    }

    async onSearchParcela(text: string): Promise<ISuggestionItem[]> {
        const katastrKod = (this.state.katastr ? this.state.katastr.kod : 0);
        return await this.props.ruianApi.getParcelSugestion(katastrKod,text);
    }

    /**
     * Nastaví zoom a střed mapy
     * @param coordinate
     * @param zoom
     */
    zoomMap(coordinate?: ICoordinate, zoom?: number)
    {
        if (coordinate)
            this.setState({ mapCenter: coordinate });
        if (zoom) {
            this.setState({ mapZoom: zoom });
        }
    }

    async GetUliceDetail(id: string) {
        console.log("ulice detail:"+id);
        const ulice = await this.props.ruianApi.getStreetDetail(id);
        if (ulice) {
            if (ulice.definicniCara && ulice.definicniCara.length > 0) {
                this.zoomMap(ulice.definicniCara[0][0], 17);

                this.setState({
                    adresa: {
                        discriminator: RuianInterfaceTypeEnum.Adresa,
                        id: ulice.id,
                        name: ulice.name,
                        type:"Ulice",
                        kod: ulice.kod,
                        description: "",
                        location: ulice.definicniCara[0][0],
                        definicniCara: ulice.definicniCara
                    }
                });
            }
        }
    }

    async GetObecDetail(id: string) {

    } 

    async katastrSelected(item: ISuggestionItem) {
        const katastr = await this.props.ruianApi.getKatastrDetail(item.tag.kod);
        this.setState({ katastr });
        if (katastr && katastr.definicniBod) {
            this.zoomMap(katastr.definicniBod, 12);
        }
    }

    async parcelaSelected(item: ISuggestionItem) {
        const parcel = await this.props.ruianApi.getParcelaDetail(item.tag.id)
        if (parcel) {
            const parcela: IZadaniObjekt = {
                key: parcel.id.toString(),
                title: parcel.kmenoveCislo.toString() + (parcel.pododdeleniCisla ? "/" + parcel.pododdeleniCisla.toString() : ""),
                polygon: parcel.hranice,
                data: parcel,
                point: parcel.definicniBod,
                discriminator: RuianInterfaceTypeEnum.Parcela
            }
            this.setState({ active: parcela, selectionPanelIsOpen: true },
                () => this.zoomMap(parcela.point, 18)
            );
            
        }
    }

    async addressSelected(item: ISuggestionItem) {
        const adresa: IAdresa = item.tag as IAdresa;
        const _this = this;
        _this.setState({ adresa: adresa }, () => { _this.zoomMap(adresa.location, 18); });
    }
    

    clearKatasr() {
        this.state.active &&
            this.state.active.discriminator === RuianInterfaceTypeEnum.Katastr &&
            this.setState({ active: undefined })
    }

    clearParcela() {
        this.state.active &&
            this.state.active.discriminator === RuianInterfaceTypeEnum.Parcela &&
            this.setState({ active: undefined })
    }

    clearAddresa() {
        this.state.active &&
            this.state.active.discriminator === RuianInterfaceTypeEnum.Adresa &&
            this.setState({ active: undefined })
    }

    hideTeachingBubble() {
        this.setState({ toggleTeachingBubbleVisible: -1 });
    }

    hideTeachingBubbleNextStep(step: number) {
        this.setState({ toggleTeachingBubbleVisible: step });
    }

    render() {

        var info = "";
        if (this.state.katastr) {
            info = "katastr: " + this.state.katastr?.nazev
        }
        const examplePrimaryButtonProps: IButtonProps = {
            children: 'Další typ',
            onClick: () => {
                this.hideTeachingBubbleNextStep(1);
                this.setState({ mapZoom: 17 })
            }
        };
        const TypMapaPrimaryButtonProps: IButtonProps = {
            children: 'Další typ',
            onClick: () => {
                this.hideTeachingBubbleNextStep(2); this.setState({ mapZoom: 17 })
            }
        };

        const exampleSecondaryButtonProps: IButtonProps = {
            children: 'Rozumím',
            onClick: this.hideTeachingBubble.bind(this),
        }

        

        const VyhledaniKatastraniUzemiPrimaryButtonProps: IButtonProps = {
            children: 'Další typ',
            onClick: () => {
                this.hideTeachingBubbleNextStep(3);
            }
        };



        return (
            <Stack>
                <SearchPanel
                    adresaSearch={this.onSearchAddress.bind(this)}
                    katastrSearch={this.onSearchKatastr.bind(this)}
                    parcelaSearch={this.onSearchParcela.bind(this)}

                    adresaCleared={this.clearAddresa.bind(this)}
                    katastrCleared={this.clearKatasr.bind(this)}
                    parcelaCleared={this.clearParcela.bind(this)}

                    adresaSeleted={this.addressSelected.bind(this)}
                    katastrSeleted={this.katastrSelected.bind(this)}
                    parcelaSeleted={this.parcelaSelected.bind(this)}

                    parcelaClearFilter={() => { }}

                    parcelaFiltrInfo={info}
                    showHelp={() => this.setState({ toggleTeachingBubbleVisible:0 })}
                />
                <Separator />
                <div style={{
                    position: 'relative',
                    minWidth: '100%',
                    minHeight: '600px',
                    height: '200px'
                }}>
                    <Map
                        center={this.state.mapCenter}
                        mapZoom={this.state.mapZoom}
                        config={this.props.configuration}
                        findParcelByLocation={this.findParcelById.bind(this)}
                        mapState={this.state.mapState}
                        adresa={this.state.adresa}
                        active={this.state.active}
                        items={this.state.items}
                        chageMapState={(state: mapStates) => this.setState({ mapState: state })}
                        setMapZoom={(zoom: number) => { this.setState({ mapZoom: zoom }) }}
                        setActivePolygone={(polygone?: ICoordinate[]) => {
                            if (polygone) {
                                const newActive: IZadaniObjekt = {
                                    discriminator: RuianInterfaceTypeEnum.Polygone,
                                    key: this.makeId(),
                                    point: (polygone.length > 0 ? polygone[0] : { elevation: 0, latitude: 0, longitude: 0 }),
                                    title: "",
                                    polygon: polygone,
                                }
                                this.setState({ active: newActive, selectionPanelIsOpen: true })
                            }
                        }
                        }
                    />
                    <div style={{ position: "absolute", left: 10, top: 10, width: "50px", height: "100px" }}>
                        <IconButton id="IconAddPolygon" styles={{ icon: { fontSize: "32px" } }} iconProps={Section} title="Zadání nového polygonu" ariaLabel="Zadání nového polygonu" disabled={false} checked={this.state.mapState === mapStates.newPolygon}
                            onClick={() => {
                                if (this.state.mapState === mapStates.view) {
                                    this.setState({ mapState: mapStates.newPolygon, active: undefined })
                                }
                                else {                                    
                                    this.setState({ mapState: mapStates.view})
                                }
                            }} />
                        <IconButton id="IconOpenPanel" styles={{ icon: { fontSize: "32px" } }} iconProps={CheckList} title="Zobrazit zadané polygony nebo parcely" ariaLabel="Zobrazit zadané polygony nebo parcely" disabled={false} checked={false}
                            onClick={() => { this.setState({ selectionPanelIsOpen: true }) }}

                        />
                    </div>
                </div>

                <InfoPanelZadaniObjekty
                    key="InfoPanelZadaniObjekty"
                    active={this.state.active}
                    items={this.state.items}
                    selectedItems={this.state.selectedItems}
                    selectionPanelIsOpen={this.state.selectionPanelIsOpen}
                    setSelected={(selected?: IZadaniObjekt[]) => {
                        this.setState({
                            selectedItems: selected, active: (selected && selected.length == 1 ? { ...selected[0] } : undefined)
                        });
                    }}
                    closePanel={() => { this.setState({ selectionPanelIsOpen: false }) }}
                    addToItems={(item?: IZadaniObjekt) => {
                        if (item) {
                            this.setState({ items: [...this.state.items, { ...item }], active: undefined });
                        }
                    }}
                    removeSelected={(items?: IZadaniObjekt[]) => {
                        if (items) {
                            const isActiveRemoved = items.map(item => { if (this.state.active && item.key === this.state.active.key) return item.key; });
                            let keyFilter =items.map(item => { return item.key; });
                            this.setState({
                                items: this.state.items.filter((item) => !keyFilter.includes(item.key)),
                                active: (isActiveRemoved.length > 0) ? undefined : this.state.active
                            });
                        }
                    }}
                />
                <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: 10 }} styles={{ root: { paddingTop: 25, paddingBottom: 25 } }}>
                    <DefaultButton text="Zpět" onClick={() => this.props.back()} />
                    <PrimaryButton disabled={!this.state.isValid} text="Pokračovat" onClick={this.saveData.bind(this)} />
                </Stack>

                {this.state.toggleTeachingBubbleVisible===0 &&
                    (
                        <TeachingBubble
                            target="#IconAddPolygon"
                            primaryButtonProps={examplePrimaryButtonProps}
                            secondaryButtonProps={exampleSecondaryButtonProps}
                           // onDismiss={this.hideTeachingBubble.bind(this)}
                            footerContent="1 ze 4"
                            headline="Zadání oblasti zájmu polygonem">
                            Pomocí tohoto nástroje můžete nakreslit polygon. Kreslení polygonu ukočíte kliknutí na počáteční bod.
                            Polygon následně přidejte do zájmových oblastí tl. "Přidat do výběru" v pravo na panelu
                        </TeachingBubble>)
                }

                {this.state.toggleTeachingBubbleVisible === 1 &&
                    (
                        <TeachingBubble
                            target="#mapa"
                           
                            primaryButtonProps={TypMapaPrimaryButtonProps}
                            secondaryButtonProps={exampleSecondaryButtonProps}
                            //onDismiss={this.hideTeachingBubble.bind(this)}
                            footerContent="2 ze 4"
                            headline="Zadání parcely ">
                            Po přiblížení mapy se zobrazí katastrální mapa. Klinkutím vyberte parcelu a přidejte do zájmových oblastí tl. "Přidat do výběru" v pravo na panelu
                            
                        </TeachingBubble>)
                }

                {this.state.toggleTeachingBubbleVisible === 2 &&
                    (
                        <TeachingBubble
                            target="#VyhledaniKatastraniUzemi"
                          
                            primaryButtonProps={VyhledaniKatastraniUzemiPrimaryButtonProps}
                            secondaryButtonProps={exampleSecondaryButtonProps}
                            //onDismiss={this.hideTeachingBubble.bind(this)}
                            footerContent="3 ze 4"
                            headline="Vyhledání místa na mapě">
                            Vyhledávat můžete podle adresy, katastrálního území a čísla parcely. Po vybrání výsledku se mapa přesune na vybrané souřadnice. 
                        </TeachingBubble>)
                }

                {this.state.toggleTeachingBubbleVisible === 3 &&
                    (
                        <TeachingBubble
                            target="#IconOpenPanel"                           
                            //primaryButtonProps={examplePrimaryButtonProps}
                            secondaryButtonProps={exampleSecondaryButtonProps}
                            //onDismiss={this.hideTeachingBubble.bind(this)}
                            footerContent="4 ze 4"
                            headline="Zobrazení vybraných míst">
                            Klinkutím na tuto ikonu zobrazíte panel s přehledem již zadaných míst. 
                        </TeachingBubble>)
                }
            </Stack>       
        );
    }

    makeId() {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < 6; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    saveData() {
        var data = [...this.state.items];
        var polygones: IPolygone[] = []
        var parcely: IParcela[] = []

        for (var i = 0; i < data.length; i++) {
           
            if (data[i].discriminator === RuianInterfaceTypeEnum.Parcela) {
                if (data[i].data) {
                    parcely.push(data[i].data as IParcela);
                }
            }
            if (data[i].discriminator == RuianInterfaceTypeEnum.Polygone) {
                console.log(data[i]);
                if (data[i].polygon)
                {
                    polygones.push({
                        hranice: [...data[i].polygon as ICoordinate[]]
                    });
                }
            }
        }
        this.props.saveUzemi(parcely, polygones);
    }

}


export const StoreToZajmoveUzemiState = (state: IAppStore) => ({
    configuration: state.configuration.config
});

export const StoreToZajmoveUzemiDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
     };
};


export default connect(StoreToZajmoveUzemiState, StoreToZajmoveUzemiDispatchToProps)(ZajmoveUzemiPage);






/* global Microsoft*/

import { ICoordinate } from "../ICoordinate";
import { GeoJsonTypeEnum } from "./GeoJsonTypeEnum";
import { GeometryCoordinate } from "./IGeoJsonCoordinate";
import { IGeoJsonFeatureCollectionProperties } from "./IGeoJsonFeatureCollection";
import { IGeoJsonProperties } from "./IGeoJsonProperties";

export interface IGeometry {
    type: GeoJsonTypeEnum;
    coordinates: GeometryCoordinate[] | GeometryCoordinate;
}

export class Geometry implements IGeometry {
    type: GeoJsonTypeEnum;
    coordinates: GeometryCoordinate[] | GeometryCoordinate;


    public static GetGeoJsonPoint(location: ICoordinate): IGeometry {
        const result: IGeometry = {
            type: GeoJsonTypeEnum.Point,
            coordinates: [location.longitude, location.latitude]
        }
        return result;
    }

    public static GetGeoJsonLine(locations: ICoordinate[]): IGeometry {
        const result: IGeometry = {
            type: GeoJsonTypeEnum.LineString,
            coordinates: locations.map((item) => [item.longitude, item.latitude])
        }
        return result;
    }


    public static BingGeometryToGeoJson(geometry: Microsoft.Maps.IPrimitive): Geometry | undefined {
        if (geometry instanceof Microsoft.Maps.Pushpin) {
            const location = geometry.getLocation();
            const g = new Geometry(GeoJsonTypeEnum.Point, [location.longitude, location.latitude])
            return g;
        }
        if (geometry instanceof Microsoft.Maps.Polyline) {
            const locations = geometry.getLocations();
            const g = new Geometry(GeoJsonTypeEnum.LineString, locations.map(item => [item.longitude, item.latitude]))
            return g;
        }
        if (geometry instanceof Microsoft.Maps.Polygon) {
            const locations = geometry.getLocations();
            const g = new Geometry(GeoJsonTypeEnum.Polygon, locations.map(item => [item.longitude, item.latitude]))
            return g;
        }
        return undefined;
    }

    private static GetPoint(coordinates: number[], metadata?: IGeoJsonProperties, defaultProperties?: IGeoJsonProperties): Microsoft.Maps.IPrimitive {

        let metadataResult: IGeoJsonProperties = {};

        if (defaultProperties) {
            metadataResult = { ...defaultProperties }
        }

        if (metadata) {
            metadataResult = { ...metadataResult, ...metadata}
        }

        const location = new Microsoft.Maps.Location(coordinates[1], coordinates[0]);
        const g = new Microsoft.Maps.Pushpin(location);
        const options:any = {};
        if (metadataResult["color"]) {
            options.color = metadataResult["color"];
        }
        if (metadataResult["icon"]) {
            options.color = metadataResult["icon"];
        }
        if (metadataResult["anchor"]) {
            options.color = metadataResult["anchor"];
        }
        if (metadataResult["color"]) {
            options.color = metadataResult["color"];
        }
        if (metadataResult["subTitle"]) {
            options.color = metadataResult["subTitle"];
        }
        if (metadataResult["title"]) {
            options.color = metadataResult["title"];
        }
        if (metadataResult["text"]) {
            options.color = metadataResult["text"];
        }
        if (metadataResult["textOffset"]) {
            options.color = metadataResult["textOffset"];
        }
        g.setOptions(options);
        return (g as any as Microsoft.Maps.IPrimitive);
    }

    private static GetPolyline(coordinates: number[][], metadata?: IGeoJsonProperties, defaultProperties?: IGeoJsonProperties): Microsoft.Maps.IPrimitive {

        let metadataResult: IGeoJsonProperties = {};

        if (defaultProperties) {
            metadataResult = { ...defaultProperties }
        }

        if (metadata) {
            metadataResult = { ...metadataResult, ...metadata }
        }

        const locations = coordinates.map(item => new Microsoft.Maps.Location(item[1], item[0]));
        const g = new Microsoft.Maps.Polyline(locations);
 
        const options: any = {};

        if (metadataResult["cursor"]) {
            options.color = metadataResult["cursor"];
        }
        if (metadataResult["strokeColor"]) {
            options.color = metadataResult["strokeColor"];
        }
        if (metadataResult["strokeDashArray"]) {
            options.color = metadataResult["strokeDashArray"];
        }
        if (metadataResult["strokeThickness"]) {
            options.color = metadataResult["strokeThickness"];
        }
        g.setOptions(options);

        return g as any as Microsoft.Maps.IPrimitive;
    }

    private static GetPolygon(coordinates: number[][], metadata?: IGeoJsonProperties, defaultProperties?: IGeoJsonProperties): Microsoft.Maps.IPrimitive {
        let metadataResult: IGeoJsonProperties = {};

        if (defaultProperties) {
            metadataResult = { ...defaultProperties }
        }

        if (metadata) {
            metadataResult = { ...metadataResult, ...metadata }
        }

        const locations = coordinates.map(item => new Microsoft.Maps.Location(item[1], item[0]));
        const g = new Microsoft.Maps.Polygon(locations);
        const options: any = {};
        if (metadataResult["cursor"]) {
            options.color = metadataResult["cursor"];
        }
        if (metadataResult["fillColor"]) {
            options.color = metadataResult["fillColor"];
        }
        if (metadataResult["strokeColor"]) {
            options.color = metadataResult["strokeColor"];
        }
        if (metadataResult["strokeDashArray"]) {
            options.color = metadataResult["strokeDashArray"];
        }
        if (metadataResult["strokeThickness"]) {
            options.color = metadataResult["strokeThickness"];
        }
        g.setOptions(options);

        return (g as any as Microsoft.Maps.IPrimitive);
    }

    public static GeoJsonToBingGeometry(geometry: Geometry, metadata: any,defaultProperties?: IGeoJsonFeatureCollectionProperties): Microsoft.Maps.IPrimitive | Microsoft.Maps.IPrimitive[] | undefined {
        if (geometry.type === GeoJsonTypeEnum.Point) {
            const g = Geometry.GetPoint(geometry.coordinates as number[], metadata, (defaultProperties ? defaultProperties.point : {}));
            return (g as Microsoft.Maps.IPrimitive);
        }

        if (geometry.type === GeoJsonTypeEnum.LineString) {
            if (Array.isArray(geometry.coordinates)) {


                const coordinates = geometry.coordinates as number[][];
                const g = Geometry.GetPolyline(coordinates, metadata, (defaultProperties ? defaultProperties.polyline : {}));
                return g;
            }
        }

        if (geometry.type === GeoJsonTypeEnum.Polygon) {
            if (Array.isArray(geometry.coordinates)) {
                const coordinates = geometry.coordinates as number[][][];
                const g = Geometry.GetPolygon(coordinates[0], metadata, (defaultProperties ? defaultProperties.polygone : {}));
                return g;
            }
        }

        if (geometry.type === GeoJsonTypeEnum.MultiPoint) {
            const result: Microsoft.Maps.IPrimitive[] = [];
            const coordinates = geometry.coordinates as number[][];
            for (let i = 0; i < coordinates.length; i++) {
                const g = Geometry.GetPoint(coordinates[i], metadata, (defaultProperties ? defaultProperties.point : {}));
                result.push(g);
            }
            return result;
        }

        if (geometry.type === GeoJsonTypeEnum.MultiPolygon) {
            const result: Microsoft.Maps.IPrimitive[] = [];
            const coordinates = geometry.coordinates as number[][][];
            for (let i = 0; i < coordinates.length; i++) {
                const g = Geometry.GetPolygon(coordinates[i], metadata, (defaultProperties ? defaultProperties.polygone : {}));
                result.push(g);
            }
            return result;
        }

        if (geometry.type === GeoJsonTypeEnum.MultiLineString) {
            const result: Microsoft.Maps.IPrimitive[] = [];
            const coordinates = geometry.coordinates as number[][][];
            for (let i = 0; i < coordinates.length; i++) {
                const g = Geometry.GetPolyline(coordinates[i], metadata, (defaultProperties ? defaultProperties.polyline : {}));
                result.push(g);
            }
            return result;
        }
        return undefined;
    }

    constructor(type: GeoJsonTypeEnum, coordinates: GeometryCoordinate[] | GeometryCoordinate) {
        this.type = type;
        this.coordinates = coordinates;
    }

}


import * as React from 'react'
import { UpresneniZadostiForm } from './UpresneniZadostiForm'
import { IPriloha } from '../../../Types/Zadost/IPriloha';
import { IUpresneniZadani } from '../../../Types/Zadost/IUpresneniZadani';
import { Stack, PrimaryButton, DefaultButton } from '@fluentui/react';
import { KeyValuePair, rebex, validate, checkAllRules, validateLasValues } from '../../Validation';


interface IUpresneniZadostniProps {
    upresneniZadosti: IUpresneniZadani,
    prilohy: IPriloha[]
    uploading: boolean;
    deleteFiles: (files: IPriloha[]) => void;
    uploadFiles: (file: File[], callback: (progress: number, fileName:string) => void) => void;

    updateUpresneniZadani: (data: IUpresneniZadani) => void;
    back: () => void;
}

interface IUpresneniZadostniState {   
    upresneniZadosti: IUpresneniZadani;
    validatition: KeyValuePair;
    isValid: boolean;
}

export class UpresneniZadosti extends React.Component<IUpresneniZadostniProps, IUpresneniZadostniState>
{
    constructor(props: IUpresneniZadostniProps) {
        super(props);
        this.state = {
            upresneniZadosti: {
                id: 0,
                zadostId: 0,
                nazevStavby: "",
                ucelStavby: "",
                dgn: false,
                podminkyPripojeni: false,
                duvodZadosti: "",
                doplnujiciInfo: "",
                postou:false
            },
            validatition: {
                "duvodZadosti": {
                    required: true,
                    lastValue: this.props.upresneniZadosti.duvodZadosti,
                    validationError: "Musíte vybrat důvod žádosti",
                    isValid: true,
                    validated: false
                },
                "ucelStavby": {
                    required: true,
                    lastValue: this.props.upresneniZadosti.duvodZadosti,
                    validationError: "Musíte vybrat účel stavby",
                    isValid: true,
                    validated: false
                }

            },
            isValid: false
        };
    }

    componentDidMount() {
        this.setState({
            upresneniZadosti: { ...this.props.upresneniZadosti }
        });
        if (this.props.upresneniZadosti.duvodZadosti != "") {
            this.setState({
                validatition: {
                    "duvodZadosti": {
                        required: true,
                        lastValue: this.props.upresneniZadosti.duvodZadosti,
                        validationError: "Musíte vybrat důvod žádosti",
                        isValid: true,
                        validated: false
                    }
                }
            }, () => {
                const Valid = validateLasValues(this.state.validatition);
                this.setState({
                    validatition: Valid
                })
            });
        }
    }

    componentDidUpdate(prevProps: IUpresneniZadostniProps, prevState: IUpresneniZadostniState) {
        if (prevProps.upresneniZadosti != this.props.upresneniZadosti) {
            this.setState({ upresneniZadosti: { ...this.state.upresneniZadosti, id: this.props.upresneniZadosti.id} });
        }

        if (this.state.validatition != prevState.validatition) {
            let valid = checkAllRules(this.state.validatition);
            console.log("UpresneniZadosti: componentDidUpdate validatition changed:" + valid)
            this.setState({ isValid: valid});
        }


    }

    fieldChangedBind = this.fieldChanged.bind(this);
    fieldChanged(name: string, value: string | boolean) {
        this.setState({
            upresneniZadosti: {
                ...this.state.upresneniZadosti, [name]: value
            },
            validatition: { ...this.state.validatition, [name]: validate(value.toString(), this.state.validatition[name]) }

        }, () => console.log(this.state.upresneniZadosti));
    }

    render() {
        return (
            <Stack styles={{ root: { paddingTop: 25 } }}>
                <UpresneniZadostiForm
                    validation={this.state.validatition}
                    uploading={this.props.uploading}
                    disabled={false }
                    deleteFiles={this.props.deleteFiles}
                    fieldChanged={this.fieldChangedBind}
                    fileItems={this.props.prilohy}
                    uploadFiles={this.props.uploadFiles}
                    upresneni={this.state.upresneniZadosti}
            />
                <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: 10 }} styles={{ root: { paddingTop: 25, paddingBottom: 25 } }}>
                    <DefaultButton text="Zpět" disabled={this.props.uploading} onClick={() => this.props.back()} />
                    <PrimaryButton disabled={!this.state.isValid || this.props.uploading} text="Pokračovat" onClick={() => {                        
                        this.props.updateUpresneniZadani(this.state.upresneniZadosti)
                    }
                    } />
                </Stack>
            </Stack>
        )
    }
   
}
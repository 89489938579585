import { Stack, Label, DetailsListLayoutMode, MarqueeSelection, DetailsList, Selection, SelectionDirection, SelectionMode, IColumn, Link } from "@fluentui/react"
import * as React from "react";
import { IZadostListItem } from '../../../Types/Admin/ZadostListItem'
import { ZadostStavEnum } from "../../../Types/Zadost/ZadostStavEnum";
import { stavZadosti } from '../ZadostDetail/ZadostDetailForm';




interface IZadostListFormProps {
    onSelectZadost: (zadost?: IZadostListItem) => void;
    zadosti?: IZadostListItem[]
}

const _columns = [
    { key: 'id', name: 'Id', fieldName: 'id', minWidth: 60, maxWidth: 60, isResizable: true },
    { key: 'datum', name: 'Datum', fieldName: 'datum', minWidth: 60, maxWidth: 60, isResizable: true },
    { key: 'firma', name: 'Firma', fieldName: 'firma', minWidth: 60, maxWidth: 300, isResizable: true },
    { key: 'kontakt', name: 'Kontakt', fieldName: 'kontakt', minWidth: 60, maxWidth: 300, isResizable: true },
    { key: 'stavId', name: 'Stav', fieldName: 'stavId', minWidth: 100, maxWidth: 200, isResizable: true },
    { key: 'vyjadreniId', name: 'Vyjadření', fieldName: 'vyjadreniId', minWidth: 100, maxWidth: 200, isResizable: true },
];

export const ZadostListForm: React.FC<IZadostListFormProps> = (props) => {
    
    const [selectionState] = React.useState(new Selection({
        onSelectionChanged: () => {
            const s = selectionState.getSelection();
            if (s && s.length === 1) {
                props.onSelectZadost(s[0] as IZadostListItem);
            }
            else {
                props.onSelectZadost(undefined);   
            }
        }
    }));

    const formatDate = (date: string) => {
        var parts = date.split("-")
        var datum = parts[2].split("T")[0] + "." + parts[1] + "." + parts[0];
        return datum;
    }

    const renderItemColumn = (item: IZadostListItem, index?: number, column?: IColumn) => {
        if (index != undefined && column != undefined) {
            const fieldContent = item[column.fieldName as keyof IZadostListItem];
            switch (column.key) {                
                case 'vyjadreniId':
                    return <Link>{fieldContent}</Link>;
                case 'stavId':
                    return <span>{stavZadosti[fieldContent as number]}</span>;
                case 'datum':
                    return <span>{formatDate(fieldContent as string)}</span>
                default:
                    return <span>{fieldContent}</span>;
            }
        }
        else {
            return (<div></div>)
        }
    }



    return (<Stack>        
        <Label>Seznam žádostí</Label>
        {props.zadosti?
      
            <DetailsList
                items={props.zadosti}
                columns={_columns}
                
                setKey="set"
                layoutMode={DetailsListLayoutMode.justified}
                selectionMode={SelectionMode.single}
                onRenderItemColumn={renderItemColumn}
                selection={selectionState}
                selectionPreservedOnEmptyClick={true}
                ariaLabelForSelectionColumn="Toggle selection"
                ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                checkButtonAriaLabel="select row"
            />
            : null}
    </Stack>);
}
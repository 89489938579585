import { Stack, Separator, ChoiceGroup, IChoiceGroupOption } from "@fluentui/react"
import * as React from "react";
import { ZadostListForm } from "./ZadostListForm";
import { ZadostListFilterForm } from "./ZadostListFilterForm";
import { useEffect } from "react";
import { IZadostListItem } from "../../../Types/Admin/ZadostListItem";
import { IZadostApi } from "../../../Services/IZadostApi";
import { IZadostFilter } from "../../../Types/Admin/IZadostFilter";
import { IApiError } from "../../../Types/IApiError";
import { ZadostiMapForm } from './ZadostiMapForm';

interface IZadostListProps {
    onSelectZadost: (zadost?: IZadostListItem) => void;
    zadostApi: IZadostApi;
}

export const ZadostList: React.FC<IZadostListProps> = (props) => {

    const [filter, setFilter] = React.useState<IZadostFilter>({ nevyrizene: true, duvodZadosti: "", ucelStavby:"",text:"" });
    const [zadosti,setZadosti] = React.useState <IZadostListItem[]>();
    const [viewType, setViewType] = React.useState("list");
    const getData = async () => {
        var zadosti = await props.zadostApi.getZadostList(filter);
        console.log(zadosti);
        if (!(zadosti as IApiError).messageId) {
            setZadosti(zadosti as IZadostListItem[]);
        }
        else {
            /*ERROR*/ 
            console.log(zadosti);
        }
    }
    useEffect(() => {
        document.title = `Správa žádostí`;
        getData();
    }, [filter]);

    const filterChaged = (filter: IZadostFilter) => {
        setFilter({ ...filter });
    }
    const viewChanged = (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption) => {
        if (option) {
            setViewType(option.key);
        }
    }

    return (<Stack tokens={{ childrenGap:10 }} styles={{
        root: {
            width: '100%'
        }
    }}>
        <ZadostListFilterForm filter={filter} onFilterChanged={filterChaged} />
        <Separator />
        <ChoiceGroup selectedKey={viewType} onChange={viewChanged}
                styles={{ flexContainer: { display: "flex" } }} options={[
                    { key: 'list', text: 'Zobrazit data jako seznam\u00A0\u00A0' },
                { key: 'map', text: 'Zobrazit data v mapě' }]} />
        {(viewType === "list" ?
            <ZadostListForm zadosti={zadosti} onSelectZadost={props.onSelectZadost} />
            :
            <ZadostiMapForm zadosti={zadosti} wmsUrl="" mapZoom={15} onSelectZadost={props.onSelectZadost}  />)}

    </Stack>);
}
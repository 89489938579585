import { Stack, Checkbox, ComboBox, IComboBoxStyles, IComboBoxOption, IComboBox, PrimaryButton, TextField, Dropdown, IDropdownOption } from "@fluentui/react"
import { IZadostFilter } from "../../../Types/Admin/IZadostFilter";
import * as React from "react";

interface IZadostListFilterFormProps {
    onFilterChanged: (filtr: IZadostFilter) => void;
    filter: IZadostFilter;
}
const duvodZadostiOptions: IComboBoxOption[] = [
    { key: '', text: 'Nerozhoduje' },
    { key: 'B', text: 'Informace o existenci a poloze vedení' },
    { key: 'C', text: 'Územní souhlas, územní rozhodnutí' },
    { key: 'D', text: 'Stavební povolení nebo sloučené územní a stavební řízení' },
    { key: 'E', text: 'Ohlášení stavby' },
    { key: 'G', text: 'Změna užívání stavby' },
    { key: 'H', text: 'Havárie' },
    { key: 'J', text: 'Jiné (s textovým polem pro manuální vypsání účelu)' },
];

const ucelStavbyOptions: IComboBoxOption[] = [
    { key: '', text: 'Nerozhoduje' },
    { key: 'B', text: 'Bytové domy' },
    { key: 'C', text: 'Rodinné domy, garáže' },
    { key: 'D', text: 'Administrativní budovy' },
    { key: 'E', text: 'Inženýrské sítě' },
    { key: 'G', text: 'Průmyslové zony' },
    { key: 'H', text: 'Drobná stavební činnost' },
    { key: 'I', text: 'Pozemní komunikace' },
    { key: 'J', text: 'Výsadba zeleně' },
    { key: 'J1', text: 'Zepmní práce, teréní úpravy' },
    { key: 'J2', text: 'Oplocení' }
];

export const ZadostListFilterForm: React.FC<IZadostListFilterFormProps> = (props) => {

    const [filter, setFilter] = React.useState<IZadostFilter>(props.filter);
    
    const onChange = (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
     
        setFilter({ ...filter, nevyrizene: checked ? true : false });
    };

    const textChanged = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setFilter({ ...filter, text: newValue });
    }
    const duvodZadostiOnChage = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption | undefined): void => {
      
        setFilter({ ...filter, duvodZadosti: item ? item.key.toString() : "" });
    }
    const UcelStavbyOnChaged = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption | undefined): void => {        
        setFilter({ ...filter, ucelStavby: item ? item.key.toString() : "" });
    }

    return (<Stack tokens={{ childrenGap: 10 }} horizontal verticalAlign="end" styles={{
        root: {
            width: '100%', marginTop: 15,
    }
    }}>
        <Checkbox checked={filter.nevyrizene} onChange={onChange} label="Pouze nevyřízené" />
        <TextField label="Hledaný text" value={filter.text} onChange={textChanged} styles={{ root: { minWidth: 100, maxWidth: 300, width:'100%' } }} />
        <Dropdown
            selectedKey={filter.duvodZadosti}
            placeholder="Vyberte důvod žádosti o vyjídření"
            key="duvodZadosti"
            label="Důvod žádosti vyjádření"
            options={duvodZadostiOptions}
            onChange={duvodZadostiOnChage}
        />
        <Dropdown
            selectedKey={filter.ucelStavby}
            label="Účel Stavby"
            placeholder="Vyberte účel stavby"
            key="ucelStavby"
            options={ucelStavbyOptions}
            onChange={UcelStavbyOnChaged}
        />

        <PrimaryButton onClick={() => { props.onFilterChanged(filter) }}>Vyhledej</PrimaryButton>
    </Stack>);
}